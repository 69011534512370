import { TextField, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { GiJumpingDog } from "react-icons/gi";

interface GoToButtonProps {
  totalPages: number;
  onPageChange: (page: number) => void;
}

const GoToButton: React.FC<GoToButtonProps> = ({
  totalPages,
  onPageChange,
}) => {
  const [inputPage, setInputPage] = useState<string>("");
  const [isGoToActive, setIsGoToActive] = useState(false);

  const handlePageInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setInputPage(event.target.value);
  };

  const handlePageInputSubmit = () => {
    if (!inputPage) return;
    let newPage = parseInt(inputPage, 10);
    if (isNaN(newPage) || newPage <= 0) {
      newPage = 1;
    } else if (newPage > totalPages) {
      newPage = totalPages;
    }
    setInputPage("");
    setIsGoToActive(false);
    onPageChange(newPage);
  };

  const handlePageInputKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === "Enter") {
      handlePageInputSubmit();
    }
  };

  const handlePageInputBlur = () => {
    setInputPage("");
    setIsGoToActive(false);
  };

  return isGoToActive ? (
    <TextField
      value={inputPage}
      onChange={handlePageInputChange}
      onBlur={handlePageInputBlur}
      onKeyDown={handlePageInputKeyDown}
      autoFocus
      size='small'
      inputProps={{
        style: {
          textAlign: "center",
          padding: 0,
          height: 24,
        },
      }}
      style={{
        verticalAlign: "middle",
        height: 24,
        width: 24,
        minWidth: 24,
      }}
    />
  ) : totalPages > 3 ? (
    <Tooltip title={`Go to Page (1 to ${totalPages})`} placement='top'>
      <button
        onClick={() => setIsGoToActive(true)}
        className='text-slate-500 border border-slate-300 rounded w-6 p-0.5'
      >
        <GiJumpingDog size={18} />
      </button>
    </Tooltip>
  ) : null; // Return `null` instead of `false`
};

export default GoToButton;
