import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { ServerError } from "@apollo/client/link/utils";
import { isTokenExpired } from "./utils/auth";

const uri = import.meta.env.VITE_API_URI;

const allowedOperationsWithoutToken = ["AuthenticateUserUsingAccessToken"];

const httpLink = createHttpLink({
  uri: uri,
  credentials: "include", // Ensure credentials are included in the request
});

const authLink = setContext((operation, { headers }) => {
  const token = localStorage.getItem("jwt");

  // Redirect the user to the login page when the token expires
  if (
    // Check if the operation is in the allowed list
    !allowedOperationsWithoutToken.includes(operation.operationName || "") &&
    // Check if the token is expired
    isTokenExpired(token) &&
    // Check if already been redirected to the login page
    !localStorage.getItem("redirectAfterLogin")
  ) {
    localStorage.setItem("redirectAfterLogin", window.location.pathname);
    window.location.href = "/login";
    return { headers }; // Return early since we're redirecting
  }

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      console.log(`[SF-Veritas GraphQL error]: Message: ${message}`);
    });
  }

  if (networkError) {
    console.log(`[SF-Veritas Network error]: ${networkError}`);
    console.log(`[SF-Veritas Network error.name]: ${networkError.name}`);
    console.log("[SF-Veritas]", networkError);

    if (networkError.name === "ServerError") {
      // Cast networkError as ServerError to access the response property
      const serverError = networkError as ServerError;
      if (serverError.response && serverError.statusCode === 401) {
        localStorage.removeItem("jwt");
        localStorage.setItem("redirectAfterLogin", window.location.pathname);
        window.location.href = "/login";
      }
    }
  }
});

const client = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  cache: new InMemoryCache(),
});

export default client;
