import React, { ReactElement, ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import IconButton from "../IconButton";

interface ApplicationPageProps {
  pageName: string;
  helpLink?: string;
  helpTooltip?: string;
  children: ReactNode;
  CtaButton?: ReactElement; // Accepts a React element as a prop
}

const ApplicationPageWithCta: React.FC<ApplicationPageProps> = ({
  pageName,
  children,
  helpLink,
  helpTooltip,
  CtaButton,
}) => {
  return (
    <div className='h-full w-full'>
      <div
        id='headerContainer'
        className='flex items-center w-full overflow-hidden mb-3.5 pb-0.5 justify-between'
      >
        <span id='applicationPageHeader' className='text-lg font-semibold'>
          {pageName}
        </span>
        <div className="flex gap-2">
          {CtaButton && <div className='flex items-center'>{CtaButton}</div>}
          {helpLink && (
            <NavLink to={helpLink} className='link' target='_blank'>
              <IconButton
                size='text-lg'
                icon={AiOutlineQuestionCircle}
                tooltip={helpTooltip}
                noShadow
              />
            </NavLink>
          )}
        </div>
      </div>
      {children}
    </div>
  );
};

export default ApplicationPageWithCta;
