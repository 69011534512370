import React, { useState } from "react";
import "tailwindcss/tailwind.css"; // Ensure Tailwind CSS is imported

interface ToggleIconButtonProps {
  onClick: () => void;
  size: string; // Tailwind CSS text size class, e.g., 'text-2xl'
  flag: boolean; // True/false state flag
  trueColor: string; // Tailwind CSS color class for true state, e.g., 'text-slate-400'
  falseColor: string; // Tailwind CSS color class for false state, e.g., 'text-black'
  icon: React.ElementType; // Default icon component
  trueIcon?: React.ElementType; // Icon component for true state (optional)
  falseIcon?: React.ElementType; // Icon component for false state (optional)
  tooltipBgColor?: string; // Tailwind CSS background color class for tooltip (optional, default: 'bg-white')
  trueTooltip: string; // Tooltip text for true state
  falseTooltip: string; // Tooltip text for false state
  disabled?: boolean; // True/false button disabled
}

const ToggleIconButton: React.FC<ToggleIconButtonProps> = ({
  onClick,
  size,
  flag,
  trueColor,
  falseColor,
  icon: Icon,
  trueIcon: TrueIcon,
  falseIcon: FalseIcon,
  tooltipBgColor = "bg-slate-50",
  trueTooltip,
  falseTooltip,
  disabled,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className='flex items-center space-x-4'>
      <div className='relative flex items-center'>
        <button
          onClick={onClick}
          className={`${size} ${
            disabled ? "text-gray-400" : flag ? trueColor : falseColor
          } focus:outline-none`}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          disabled={disabled}
        >
          {flag ? (
            TrueIcon ? (
              <TrueIcon />
            ) : (
              <Icon />
            )
          ) : FalseIcon ? (
            <FalseIcon />
          ) : (
            <Icon />
          )}
        </button>
        {isHovered && (
          <div
            className={`absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-max text-sm text-black ${tooltipBgColor} rounded p-2 opacity-100 transition-opacity duration-200 shadow-xl`}
          >
            {flag ? trueTooltip : falseTooltip}
          </div>
        )}
      </div>
    </div>
  );
};

export default ToggleIconButton;
