import React from "react";

interface VideoPlayerRulerProps {
  containerWidth: number;
}

const GAP_PX = 14; // Gap between ticks in px
const TICK_WIDTH_PX = 1; // Width of each tick in px
const LONG_TICK_HEIGHT_PX = 14; // Height of long ticks in px
const SHORT_TICK_HEIGHT_PX = 8; // Height of small ticks in px

const VideoPlayerRuler: React.FC<VideoPlayerRulerProps> = ({
  containerWidth,
}) => {
  const rulerDivisions = Math.ceil(containerWidth / (GAP_PX + TICK_WIDTH_PX)); // Total number of ticks

  return (
    <div className='w-full border-b border-slate-300 mt-2'>
      <div className='flex w-full items-end gap-3.5'>
        {Array.from({ length: rulerDivisions }).map((_, i) => (
          <div
            key={i}
            className='bg-slate-300'
            style={{
              width: TICK_WIDTH_PX,
              height: i % 5 === 0 ? LONG_TICK_HEIGHT_PX : SHORT_TICK_HEIGHT_PX,
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default VideoPlayerRuler;
