import React from "react";
import { Duration } from "luxon";

interface TimePointDisplayIndividualProps {
  containerWidth: number;
  videoDurationSeconds: number;
  disabled: boolean;
}

const PIXELS_PER_TIME_POINT = 60;

const TimePointDisplayIndividual: React.FC<TimePointDisplayIndividualProps> = ({
  containerWidth,
  videoDurationSeconds,
  disabled,
}) => {
  // Logic to calculate intervals
  const maxTimePoints = Math.floor(containerWidth / PIXELS_PER_TIME_POINT);
  const interval = Math.ceil(videoDurationSeconds / maxTimePoints);
  const timePoints = Array.from(
    { length: Math.ceil(videoDurationSeconds / interval) },
    (_, i) => i * interval,
  );

  const formatTimePoint = (timePoint: number) => {
    const duration = Duration.fromObject({ seconds: timePoint });
    const {
      days = 0,
      hours = 0,
      minutes = 0,
      seconds = 0,
    } = duration.shiftTo("days", "hours", "minutes", "seconds").toObject();

    if (days > 0) return `${days}d${hours}h`;
    if (hours > 0) return `${hours}h${minutes}m`;
    return `${minutes ? `${minutes}m` : ""}${seconds}s`;
  };

  return (
    <div className='relative w-full flex text-xs text-gray-600 my-2 h-4'>
      {timePoints.map((timePoint, index) => {
        const formattedTimePoint = formatTimePoint(timePoint);
        return (
          <div
            key={index}
            className={`absolute text-center ${
              disabled ? "text-gray-400" : "text-black"
            }`}
            style={{
              left: `calc(${(timePoint / videoDurationSeconds) * 100}% - ${
                timePoint ? (formattedTimePoint.length * 7) / 2 : 0
              }px)`,
            }}
          >
            <span>{formattedTimePoint}</span>
          </div>
        );
      })}
    </div>
  );
};

export default TimePointDisplayIndividual;
