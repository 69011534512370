import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** Date with time (isoformat) */
  DateTime: { input: any; output: any; }
  /** A duration of time that can be parsed flexibly */
  Duration: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](https://ecma-international.org/wp-content/uploads/ECMA-404_2nd_edition_december_2017.pdf). */
  JSON: { input: any; output: any; }
  UUID: { input: any; output: any; }
  Upload: { input: any; output: any; }
  /** Represents NULL values */
  Void: { input: any; output: any; }
};

export type AllowedComparators = {
  __typename?: 'AllowedComparators';
  boolean: Array<Scalars['String']['output']>;
  json: Array<Scalars['String']['output']>;
  numeric: Array<Scalars['String']['output']>;
  string: Array<Scalars['String']['output']>;
  time: Array<Scalars['String']['output']>;
};

export type AnnouncementType = {
  __typename?: 'AnnouncementType';
  content: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  type: Scalars['String']['output'];
};

export type AuthResponse = {
  __typename?: 'AuthResponse';
  email: Scalars['String']['output'];
  message: Scalars['String']['output'];
  name: Scalars['String']['output'];
  picture: Scalars['String']['output'];
  token: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type BigQueryResultsType = {
  __typename?: 'BigQueryResultsType';
  queryType: Scalars['String']['output'];
  results: BigQueryVaryingStructurePaginationWindow;
  tree: Scalars['String']['output'];
};

export type BigQueryVaryingStructure = {
  __typename?: 'BigQueryVaryingStructure';
  data: Scalars['JSON']['output'];
};

export type BigQueryVaryingStructurePaginationWindow = {
  __typename?: 'BigQueryVaryingStructurePaginationWindow';
  /** The list of items in this pagination window. */
  items: Array<BigQueryVaryingStructure>;
  /** Total number of items in the filtered dataset. */
  totalItemsCount: Scalars['Int']['output'];
};

export type CaptureSettingsInput = {
  collectFonts?: InputMaybe<Scalars['Boolean']['input']>;
  ignoreDomains?: InputMaybe<Array<Scalars['String']['input']>>;
  inlineImages?: InputMaybe<Scalars['Boolean']['input']>;
  recordCanvas?: InputMaybe<Scalars['Boolean']['input']>;
  recordCreditCardInfo?: InputMaybe<Scalars['Boolean']['input']>;
  recordCrossOriginIframes?: InputMaybe<Scalars['Boolean']['input']>;
  recordDob?: InputMaybe<Scalars['Boolean']['input']>;
  recordPassword?: InputMaybe<Scalars['Boolean']['input']>;
  recordRealName?: InputMaybe<Scalars['Boolean']['input']>;
  recordSsn?: InputMaybe<Scalars['Boolean']['input']>;
  sampling?: InputMaybe<Scalars['JSON']['input']>;
};

export type CaptureSettingsType = {
  __typename?: 'CaptureSettingsType';
  canEdit: Scalars['Boolean']['output'];
  collectFonts: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  ignoreDomains: Array<Scalars['String']['output']>;
  inlineImages: Scalars['Boolean']['output'];
  recordCanvas: Scalars['Boolean']['output'];
  recordCreditCardInfo: Scalars['Boolean']['output'];
  recordCrossOriginIframes: Scalars['Boolean']['output'];
  recordDob: Scalars['Boolean']['output'];
  recordPassword: Scalars['Boolean']['output'];
  recordRealName: Scalars['Boolean']['output'];
  recordSsn: Scalars['Boolean']['output'];
  sampling: Scalars['JSON']['output'];
};

export type ClickInteraction = {
  __typename?: 'ClickInteraction';
  children?: Maybe<Array<Scalars['String']['output']>>;
  details: Scalars['String']['output'];
  element: Scalars['JSON']['output'];
  textContent: Scalars['String']['output'];
  timestamp?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type ClientDetails = {
  __typename?: 'ClientDetails';
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

export type CompanyConfigurationSplitNonsessionApplogsBySeconds = {
  __typename?: 'CompanyConfigurationSplitNonsessionApplogsBySeconds';
  splitNonsessionApplogsBySeconds: Scalars['Int']['output'];
};

export type CompanyType = {
  __typename?: 'CompanyType';
  apiKey: Scalars['UUID']['output'];
  domains: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  users: Array<DjangoModelType>;
};

export type ConsoleLog = {
  __typename?: 'ConsoleLog';
  /** Human-readable timestamp */
  formattedTimestamp: Scalars['String']['output'];
  level: Scalars['String']['output'];
  payload: Scalars['String']['output'];
  timestamp: Scalars['String']['output'];
  trace: Array<Scalars['String']['output']>;
};

export type CreateIssueInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  triageIds: Array<Scalars['String']['input']>;
};

export type DeviceDetails = {
  __typename?: 'DeviceDetails';
  brand?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type DeviceMetadata = {
  __typename?: 'DeviceMetadata';
  client?: Maybe<ClientDetails>;
  device?: Maybe<DeviceDetails>;
  isBot: Scalars['Boolean']['output'];
  isDesktop: Scalars['Boolean']['output'];
  isMobile: Scalars['Boolean']['output'];
  isTelevision: Scalars['Boolean']['output'];
  os?: Maybe<OsDetails>;
};

export type DjangoModelType = {
  __typename?: 'DjangoModelType';
  pk: Scalars['ID']['output'];
};

export type DnsRoutingStatusType = {
  __typename?: 'DnsRoutingStatusType';
  backendApi: Scalars['Boolean']['output'];
  sailfishBackendApi: Scalars['Boolean']['output'];
};

export type EmailNotificationSettingsInput = {
  defaultRecipients?: InputMaybe<Scalars['JSON']['input']>;
  timeBetweenSuccessiveNotificationsPerIssue: Scalars['Duration']['input'];
};

export type EmailNotificationSettingsType = {
  __typename?: 'EmailNotificationSettingsType';
  defaultRecipients: Scalars['JSON']['output'];
  timeBetweenSuccessiveNotificationsPerIssue: Scalars['Duration']['output'];
};

export type ExceptionRecordType = {
  __typename?: 'ExceptionRecordType';
  code?: Maybe<Scalars['String']['output']>;
  exceptionMessage?: Maybe<Scalars['String']['output']>;
  file?: Maybe<Scalars['String']['output']>;
  function?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  issue?: Maybe<DjangoModelType>;
  line?: Maybe<Scalars['Int']['output']>;
  serviceIdentifier?: Maybe<DjangoModelType>;
  timestampMs: Scalars['String']['output'];
  traceJson?: Maybe<Scalars['JSON']['output']>;
  /** Trace stack */
  traceStack: Array<TraceFrameType>;
};

export type FeatureRequestType = {
  __typename?: 'FeatureRequestType';
  /** List of companies that voted for the feature request */
  companiesVoted: Array<Scalars['String']['output']>;
  dateRequested: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastUpdate: Scalars['DateTime']['output'];
  title: Scalars['String']['output'];
  /** Number of votes for the feature request */
  totalVotes: Scalars['Int']['output'];
  /** Whether the user voted for the feature request */
  votedByUser: Scalars['Boolean']['output'];
};

export type FieldAutocompleteData = {
  __typename?: 'FieldAutocompleteData';
  field: Scalars['String']['output'];
  matches: Match;
};

export type FollowIssueInput = {
  issueId: Scalars['Int']['input'];
};

export type FullAutocompleteData = {
  __typename?: 'FullAutocompleteData';
  /** Allowed comparators for field types */
  allowedComparators: AllowedComparators;
  booleanFields: Array<TypeAheadBooleanField>;
  jsonFields: Array<TypeAheadJsonField>;
  numericFields: Array<TypeAheadNumericField>;
  textFields: Array<TypeAheadTextField>;
  timeFields: Array<TypeAheadTimeField>;
};

export type IntegrationStatusType = {
  __typename?: 'IntegrationStatusType';
  integrations: Array<IntegrationType>;
};

export type IntegrationType = {
  __typename?: 'IntegrationType';
  isWorking: Scalars['Boolean']['output'];
  link: Scalars['String']['output'];
  title: Scalars['String']['output'];
  tooltipText: Scalars['String']['output'];
};

export type InteractionsTaken = {
  __typename?: 'InteractionsTaken';
  clicks: Array<ClickInteraction>;
  pageNavigations: Array<PageNavigationInteraction>;
  textEdits: Array<TypingInteraction>;
};

export type IssueQueryResultsType = {
  __typename?: 'IssueQueryResultsType';
  queryType: Scalars['String']['output'];
  results: IssueTypePaginationWindow;
  tree: Scalars['String']['output'];
};

export type IssueType = {
  __typename?: 'IssueType';
  company: CompanyType;
  createdAt: Scalars['DateTime']['output'];
  /** Created time, human-readable timestamp */
  createdAtFormatted: Scalars['String']['output'];
  /** Identifier of who created the record */
  createdBy: Scalars['String']['output'];
  createdBySystem?: Maybe<Scalars['String']['output']>;
  createdByUser?: Maybe<DjangoModelType>;
  description?: Maybe<Scalars['String']['output']>;
  exceptions: Array<ExceptionRecordType>;
  followers: Array<UserType>;
  id: Scalars['String']['output'];
  isResolved: Scalars['Boolean']['output'];
  lastNotifiedViaEmail?: Maybe<Scalars['DateTime']['output']>;
  lastNotifiedViaWebhook?: Maybe<Scalars['DateTime']['output']>;
  lastSeen: Scalars['DateTime']['output'];
  snoozedMode: Scalars['String']['output'];
  snoozedPermanently: Scalars['Boolean']['output'];
  snoozedUntil?: Maybe<Scalars['DateTime']['output']>;
  /** Snoozed until time, human-readable timestamp */
  snoozedUntilFormatted?: Maybe<Scalars['String']['output']>;
  summary?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  triages: Array<TriageType>;
  updatedAt: Scalars['DateTime']['output'];
  /** Updated time, human-readable timestamp */
  updatedAtFormatted: Scalars['String']['output'];
  /** Identifier of who last updated the record */
  updatedBy: Scalars['String']['output'];
  updatedBySystem?: Maybe<Scalars['String']['output']>;
  updatedByUser?: Maybe<DjangoModelType>;
};

export type IssueTypePaginationWindow = {
  __typename?: 'IssueTypePaginationWindow';
  /** The list of items in this pagination window. */
  items: Array<IssueType>;
  /** Total number of items in the filtered dataset. */
  totalItemsCount: Scalars['Int']['output'];
};

export type JsSnippetConfigParametersType = {
  __typename?: 'JsSnippetConfigParametersType';
  apiKey: Scalars['String']['output'];
  backendApi: Scalars['String']['output'];
  /** JS Snippet Source */
  scriptSource: Scalars['String']['output'];
};

export type Match = {
  __typename?: 'Match';
  fuzzy: Array<Scalars['String']['output']>;
  prefix: Array<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  authenticateUser: AuthResponse;
  authenticateUserUsingAccessToken: AuthResponse;
  autoExpireOldSessions: Scalars['String']['output'];
  collectExceptions: Scalars['Boolean']['output'];
  collectLogs: Scalars['Boolean']['output'];
  collectMetadata: Scalars['Boolean']['output'];
  collectPrintStatements: Scalars['Boolean']['output'];
  createGcsBucket: Scalars['String']['output'];
  createIssue?: Maybe<IssueType>;
  createTriage?: Maybe<TriageType>;
  createUserAction: RecordingSessionUserActionType;
  dismissAnnouncement: Scalars['Boolean']['output'];
  domainsToNotPassHeaderTo: Scalars['Boolean']['output'];
  endEventSession: Scalars['String']['output'];
  finalizeSession?: Maybe<Scalars['Void']['output']>;
  followIssue: Scalars['Boolean']['output'];
  identifyServiceDetails: Scalars['Boolean']['output'];
  loginUser: UserType;
  notify503ErrorFromGcs?: Maybe<Scalars['Void']['output']>;
  reactivateSession?: Maybe<Scalars['Void']['output']>;
  registerUser: UserType;
  reportNumberOfEventsProcessedForSessionId?: Maybe<Scalars['Void']['output']>;
  sendRecordingEvent: Scalars['String']['output'];
  sendRecordingEvents: Scalars['String']['output'];
  snoozeIssue: SnoozeIssueUpdateStatus;
  startRecordingSession: RecordingSessionType;
  submitFeatureRequest?: Maybe<FeatureRequestType>;
  toggleIssueFollowingStatusForUser: Scalars['Boolean']['output'];
  toggleIssueResolvedStatus: Scalars['Boolean']['output'];
  toggleVote: FeatureRequestType;
  unfollowIssue: Scalars['Boolean']['output'];
  updateBigqueryTablesForDataset: Scalars['Boolean']['output'];
  updateCaptureSettings?: Maybe<CaptureSettingsType>;
  updateNotificationSettings: Scalars['Boolean']['output'];
  updatePageVisits: Scalars['Boolean']['output'];
  updateServiceDetails: Scalars['Boolean']['output'];
  updateSiteSettings?: Maybe<SiteSettingsType>;
  uploadSourceMap: Scalars['Boolean']['output'];
  validateSearchQuery: ValidationResult;
};


export type MutationAuthenticateUserArgs = {
  token: Scalars['String']['input'];
};


export type MutationAuthenticateUserUsingAccessTokenArgs = {
  accessToken: Scalars['String']['input'];
};


export type MutationCollectExceptionsArgs = {
  apiKey: Scalars['String']['input'];
  exceptionMessage: Scalars['String']['input'];
  library?: InputMaybe<Scalars['String']['input']>;
  reentrancyGuardPreactive?: Scalars['Boolean']['input'];
  serviceUuid?: InputMaybe<Scalars['String']['input']>;
  sessionId: Scalars['String']['input'];
  timestampMs: Scalars['String']['input'];
  traceJson: Scalars['String']['input'];
  version?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCollectLogsArgs = {
  apiKey: Scalars['String']['input'];
  contents: Scalars['String']['input'];
  level: Scalars['String']['input'];
  library?: InputMaybe<Scalars['String']['input']>;
  reentrancyGuardPreactive?: Scalars['Boolean']['input'];
  serviceUuid?: InputMaybe<Scalars['String']['input']>;
  sessionId: Scalars['String']['input'];
  timestampMs: Scalars['String']['input'];
  version?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCollectMetadataArgs = {
  apiKey: Scalars['String']['input'];
  excludedFields: Array<Scalars['String']['input']>;
  library?: InputMaybe<Scalars['String']['input']>;
  override?: Scalars['Boolean']['input'];
  serviceUuid?: InputMaybe<Scalars['String']['input']>;
  sessionId: Scalars['String']['input'];
  timestampMs: Scalars['String']['input'];
  traitsJson: Scalars['String']['input'];
  userId: Scalars['String']['input'];
  version?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCollectPrintStatementsArgs = {
  apiKey: Scalars['String']['input'];
  contents: Scalars['String']['input'];
  library?: InputMaybe<Scalars['String']['input']>;
  reentrancyGuardPreactive?: Scalars['Boolean']['input'];
  serviceUuid?: InputMaybe<Scalars['String']['input']>;
  sessionId: Scalars['String']['input'];
  timestampMs: Scalars['String']['input'];
  version?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateIssueArgs = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  input: CreateIssueInput;
};


export type MutationCreateTriageArgs = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  triageInfoLogs?: InputMaybe<Array<TriageInputLogs>>;
  triageInfoRecordings?: InputMaybe<Array<TriageInputRecording>>;
};


export type MutationCreateUserActionArgs = {
  input: RecordingSessionUserActionInput;
};


export type MutationDismissAnnouncementArgs = {
  announcementId: Scalars['Int']['input'];
};


export type MutationDomainsToNotPassHeaderToArgs = {
  apiKey: Scalars['String']['input'];
  domains: Array<Scalars['String']['input']>;
  serviceUuid?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEndEventSessionArgs = {
  companyApiKey: Scalars['UUID']['input'];
  sessionId: Scalars['String']['input'];
};


export type MutationFinalizeSessionArgs = {
  sessionId: Scalars['String']['input'];
};


export type MutationFollowIssueArgs = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  input: FollowIssueInput;
};


export type MutationIdentifyServiceDetailsArgs = {
  apiKey: Scalars['String']['input'];
  library: Scalars['String']['input'];
  serviceAdditionalMetadata?: InputMaybe<Scalars['JSON']['input']>;
  serviceIdentifier?: InputMaybe<Scalars['String']['input']>;
  serviceUuid: Scalars['String']['input'];
  serviceVersion?: InputMaybe<Scalars['String']['input']>;
  timestampMs: Scalars['String']['input'];
  version: Scalars['String']['input'];
};


export type MutationLoginUserArgs = {
  input: UserInput;
};


export type MutationReactivateSessionArgs = {
  sessionId: Scalars['String']['input'];
};


export type MutationRegisterUserArgs = {
  input: UserInput;
};


export type MutationReportNumberOfEventsProcessedForSessionIdArgs = {
  numberOfEvents: Scalars['Int']['input'];
  sessionId: Scalars['String']['input'];
};


export type MutationSendRecordingEventArgs = {
  companyApiKey: Scalars['UUID']['input'];
  event: Scalars['JSON']['input'];
  sessionId: Scalars['String']['input'];
};


export type MutationSendRecordingEventsArgs = {
  companyApiKey: Scalars['UUID']['input'];
  events: Array<Scalars['JSON']['input']>;
  sessionId: Scalars['ID']['input'];
};


export type MutationSnoozeIssueArgs = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  input: SnoozeIssueInput;
};


export type MutationStartRecordingSessionArgs = {
  companyApiKey: Scalars['UUID']['input'];
  sessionId: Scalars['UUID']['input'];
};


export type MutationSubmitFeatureRequestArgs = {
  description: Scalars['String']['input'];
  title: Scalars['String']['input'];
};


export type MutationToggleIssueFollowingStatusForUserArgs = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  input: ToggleFollowIssueInput;
};


export type MutationToggleIssueResolvedStatusArgs = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  input: ResolveIssueInput;
};


export type MutationToggleVoteArgs = {
  requestId: Scalars['ID']['input'];
};


export type MutationUnfollowIssueArgs = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  input: FollowIssueInput;
};


export type MutationUpdateBigqueryTablesForDatasetArgs = {
  name: Scalars['String']['input'];
};


export type MutationUpdateCaptureSettingsArgs = {
  input: CaptureSettingsInput;
};


export type MutationUpdateNotificationSettingsArgs = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  emailSettings?: InputMaybe<EmailNotificationSettingsInput>;
  webhookSettings?: InputMaybe<WebhookNotificationSettingsInput>;
  webhooks?: InputMaybe<Array<NotificationWebhookInput>>;
};


export type MutationUpdatePageVisitsArgs = {
  pageVisits: Array<PageVisitInput>;
  sessionId: Scalars['String']['input'];
};


export type MutationUpdateServiceDetailsArgs = {
  apiKey: Scalars['String']['input'];
  serviceAdditionalMetadata?: InputMaybe<Scalars['JSON']['input']>;
  serviceIdentifier?: InputMaybe<Scalars['String']['input']>;
  serviceUuid: Scalars['String']['input'];
  serviceVersion?: InputMaybe<Scalars['String']['input']>;
  timestampMs: Scalars['String']['input'];
};


export type MutationUpdateSiteSettingsArgs = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  input: SiteSettingsInput;
};


export type MutationUploadSourceMapArgs = {
  apiKey: Scalars['String']['input'];
  mapUuid: Scalars['String']['input'];
  sourceMap: Scalars['Upload']['input'];
};


export type MutationValidateSearchQueryArgs = {
  searchQuery: Scalars['String']['input'];
};

export type NotificationWebhookInput = {
  authentication?: InputMaybe<Scalars['JSON']['input']>;
  destinationAddress: Scalars['String']['input'];
  webhookDataType?: InputMaybe<Scalars['String']['input']>;
};

export type NotificationWebhookType = {
  __typename?: 'NotificationWebhookType';
  authentication?: Maybe<Scalars['JSON']['output']>;
  destinationAddress: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  webhookDataType: Scalars['String']['output'];
};

export type NotificationsSettingsType = {
  __typename?: 'NotificationsSettingsType';
  emailNotificationSettings: EmailNotificationSettingsType;
  notificationWebhooks: Array<NotificationWebhookType>;
  webhookNotificationSettings: WebhookNotificationSettingsType;
};

export type OsDetails = {
  __typename?: 'OSDetails';
  name?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

export type PageNavigationInteraction = {
  __typename?: 'PageNavigationInteraction';
  details: Scalars['String']['output'];
  timestamp?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type PageVisitInput = {
  timestamp: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type PageVisitType = {
  __typename?: 'PageVisitType';
  activeFrom: Scalars['String']['output'];
  activeUntil?: Maybe<Scalars['String']['output']>;
  pageUrl?: Maybe<DjangoModelType>;
  url: Scalars['String']['output'];
};

export type PaginatedAnnouncements = {
  __typename?: 'PaginatedAnnouncements';
  announcements: Array<AnnouncementType>;
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type Query = {
  __typename?: 'Query';
  allAnnouncements: PaginatedAnnouncements;
  captureSettings?: Maybe<CaptureSettingsType>;
  captureSettingsFromApiKey: CaptureSettingsType;
  companies: Array<CompanyType>;
  company: CompanyType;
  dnsRoutingStatus?: Maybe<DnsRoutingStatusType>;
  featureRequests: Array<FeatureRequestType>;
  getAutocompleteDataForField: FieldAutocompleteData;
  getFieldValues: Array<Scalars['String']['output']>;
  getFullAutocompleteData: FullAutocompleteData;
  getIssue?: Maybe<IssueType>;
  getLogsForRecording: Array<ConsoleLog>;
  getNotificationSettings: NotificationsSettingsType;
  getSearchableFields: SearchableFields;
  getTriage?: Maybe<TriageType>;
  jsSnippetConfigParameters?: Maybe<JsSnippetConfigParametersType>;
  latestBannerAnnouncement?: Maybe<AnnouncementType>;
  recordingSession?: Maybe<RecordingSessionEvents>;
  recordingSessions?: Maybe<RecordingSessionTypePaginationWindow>;
  searchUsingQuery?: Maybe<RecordingSessionQueryResultsTypeIssueQueryResultsTypeTriageQueryResultsTypeBigQueryResultsType>;
  sessionDetailsForKafkaFromApiKey: SessionDetailsForKafka;
  sessionDetailsForKafkaFromSessionId?: Maybe<SessionDetailsForKafka>;
  siteSettings?: Maybe<SiteSettingsType>;
  splitNonsessionApplogsBySecondsForApiKey: CompanyConfigurationSplitNonsessionApplogsBySeconds;
  statusItems?: Maybe<IntegrationStatusType>;
  triggerException0?: Maybe<Scalars['Void']['output']>;
  triggerException1?: Maybe<Scalars['Void']['output']>;
  triggerException2?: Maybe<Scalars['Void']['output']>;
  triggerException3?: Maybe<Scalars['Void']['output']>;
  triggerException4?: Maybe<Scalars['Void']['output']>;
  user: UserType;
};


export type QueryAllAnnouncementsArgs = {
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};


export type QueryCaptureSettingsArgs = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCaptureSettingsFromApiKeyArgs = {
  apiKey: Scalars['String']['input'];
};


export type QueryCompanyArgs = {
  apiKey: Scalars['UUID']['input'];
};


export type QueryGetAutocompleteDataForFieldArgs = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  currentSearchDataForField: Scalars['String']['input'];
  field: Scalars['String']['input'];
  itemIds?: InputMaybe<Array<Scalars['String']['input']>>;
  searchEntity: Scalars['String']['input'];
  topN?: Scalars['Int']['input'];
};


export type QueryGetFieldValuesArgs = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  fieldName: Scalars['String']['input'];
  searchEntity: Scalars['String']['input'];
};


export type QueryGetFullAutocompleteDataArgs = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  itemIds?: InputMaybe<Array<Scalars['String']['input']>>;
  searchEntity: Scalars['String']['input'];
  topN?: Scalars['Int']['input'];
};


export type QueryGetIssueArgs = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  issueId: Scalars['Int']['input'];
};


export type QueryGetLogsForRecordingArgs = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  sessionId: Scalars['String']['input'];
};


export type QueryGetNotificationSettingsArgs = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetTriageArgs = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  triageId: Scalars['Int']['input'];
};


export type QueryJsSnippetConfigParametersArgs = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRecordingSessionArgs = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['String']['input'];
};


export type QueryRecordingSessionsArgs = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};


export type QuerySearchUsingQueryArgs = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: Scalars['String']['input'];
  searchEntity: Scalars['String']['input'];
  searchQuery: Scalars['String']['input'];
};


export type QuerySessionDetailsForKafkaFromApiKeyArgs = {
  apiKey: Scalars['String']['input'];
};


export type QuerySessionDetailsForKafkaFromSessionIdArgs = {
  sessionId: Scalars['String']['input'];
};


export type QuerySiteSettingsArgs = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySplitNonsessionApplogsBySecondsForApiKeyArgs = {
  apiKey: Scalars['String']['input'];
};


export type QueryStatusItemsArgs = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTriggerException0Args = {
  id: Scalars['String']['input'];
};


export type QueryTriggerException1Args = {
  id: Scalars['String']['input'];
};


export type QueryTriggerException2Args = {
  id: Scalars['String']['input'];
};


export type QueryTriggerException3Args = {
  id: Scalars['String']['input'];
};


export type QueryTriggerException4Args = {
  id: Scalars['String']['input'];
};


export type QueryUserArgs = {
  id: Scalars['Int']['input'];
};

export type RecordingMetadata = {
  __typename?: 'RecordingMetadata';
  device: DeviceMetadata;
  language: Scalars['String']['output'];
  region?: Maybe<Scalars['String']['output']>;
  timeZone?: Maybe<Scalars['String']['output']>;
  user: Scalars['String']['output'];
};

export type RecordingSessionEvents = {
  __typename?: 'RecordingSessionEvents';
  id: Scalars['ID']['output'];
  /** Recording Metadata */
  metadata?: Maybe<RecordingMetadata>;
  /** Page visits */
  pageVisits: Array<PageVisitType>;
  /** Unzipped events content */
  unzippedEvents: Array<Scalars['JSON']['output']>;
  /** User interactions */
  userInteractions: InteractionsTaken;
};

export type RecordingSessionQueryResultsType = {
  __typename?: 'RecordingSessionQueryResultsType';
  queryType: Scalars['String']['output'];
  results: RecordingSessionTypePaginationWindow;
  tree: Scalars['String']['output'];
};

export type RecordingSessionQueryResultsTypeIssueQueryResultsTypeTriageQueryResultsTypeBigQueryResultsType = BigQueryResultsType | IssueQueryResultsType | RecordingSessionQueryResultsType | TriageQueryResultsType;

export type RecordingSessionType = {
  __typename?: 'RecordingSessionType';
  dataIgnoredDueToDomainExclusion: Scalars['Boolean']['output'];
  deviceInfo?: Maybe<Scalars['JSON']['output']>;
  /** Duration in milliseconds [ms] */
  durationMs?: Maybe<Scalars['String']['output']>;
  /** Human-readable start timestamp */
  formattedStartTimestamp: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  isZipped: Scalars['Boolean']['output'];
  numberOfEventsProcessed: Scalars['Int']['output'];
  numberOfEventsSeen: Scalars['Int']['output'];
  revision: Scalars['Int']['output'];
  rootDomain?: Maybe<DjangoModelType>;
  serviceIdentifier?: Maybe<DjangoModelType>;
  startTimestamp: Scalars['Int']['output'];
  timeZone?: Maybe<Scalars['String']['output']>;
  traits?: Maybe<Scalars['JSON']['output']>;
  userDeviceUuid?: Maybe<Scalars['UUID']['output']>;
  userId: Scalars['String']['output'];
  userIdHasBeenSet: Scalars['Boolean']['output'];
};

export type RecordingSessionTypePaginationWindow = {
  __typename?: 'RecordingSessionTypePaginationWindow';
  /** The list of items in this pagination window. */
  items: Array<RecordingSessionType>;
  /** Total number of items in the filtered dataset. */
  totalItemsCount: Scalars['Int']['output'];
};

export type RecordingSessionUserActionInput = {
  actionType: Scalars['String']['input'];
  dotPath: Scalars['String']['input'];
  recordingSessionId: Scalars['String']['input'];
  textContent: Scalars['String']['input'];
  timestamp: Scalars['DateTime']['input'];
};

export type RecordingSessionUserActionType = {
  __typename?: 'RecordingSessionUserActionType';
  actionType: Scalars['String']['output'];
  dotPath: Scalars['String']['output'];
  id: Scalars['String']['output'];
  recordingSessionId: Scalars['String']['output'];
  textContent: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
};

export type RecordingTriageTimeWindow = {
  timestampEnd: Scalars['Int']['input'];
  timestampStart: Scalars['Int']['input'];
};

export type ResolveIssueInput = {
  issueId: Scalars['Int']['input'];
  setResolvedStateTo: Scalars['Boolean']['input'];
};

export type SearchableFields = {
  __typename?: 'SearchableFields';
  booleanFields: Array<Scalars['String']['output']>;
  numericFields: Array<Scalars['String']['output']>;
  textFields: Array<Scalars['String']['output']>;
  timeFields: Array<Scalars['String']['output']>;
};

export type SessionDetailsForKafka = {
  __typename?: 'SessionDetailsForKafka';
  bucketName: Scalars['String']['output'];
  datasetId: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  isZipped: Scalars['Boolean']['output'];
  splitNonsessionApplogsBySeconds: Scalars['Int']['output'];
};

export type SiteSettingsInput = {
  backendApi?: InputMaybe<Scalars['String']['input']>;
  sailfishBackendApi?: InputMaybe<Scalars['String']['input']>;
};

export type SiteSettingsType = {
  __typename?: 'SiteSettingsType';
  /** API Key for use in application integrations */
  apiKey: Scalars['String']['output'];
  backendApi: Scalars['String']['output'];
  /** Is the Backend API URL the default, or modified */
  defaultBackendApiUrl: Scalars['String']['output'];
  /** Is the Backend API URL the default, or modified */
  defaultSailfishBackendApiUrl: Scalars['String']['output'];
  /** Is the Backend API URL the default, or modified */
  isDefaultBackendApiUrl: Scalars['Boolean']['output'];
  /** Is the Sailfish Backend API URL the default, or modified */
  isDefaultSailfishBackendApiUrl: Scalars['Boolean']['output'];
  sailfishBackendApi: Scalars['String']['output'];
};

export type SnoozeIssueInput = {
  issueId: Scalars['Int']['input'];
  snoozedMode?: InputMaybe<Scalars['String']['input']>;
  snoozedPermanently?: InputMaybe<Scalars['Boolean']['input']>;
  snoozedUntil?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SnoozeIssueUpdateStatus = {
  __typename?: 'SnoozeIssueUpdateStatus';
  id: Scalars['ID']['output'];
  snoozedMode: Scalars['String']['output'];
  snoozedPermanently: Scalars['Boolean']['output'];
  snoozedUntil?: Maybe<Scalars['DateTime']['output']>;
};

export type ToggleFollowIssueInput = {
  issueId: Scalars['Int']['input'];
  wantsToFollow: Scalars['Boolean']['input'];
};

export type TraceFrameType = {
  __typename?: 'TraceFrameType';
  code: Scalars['String']['output'];
  file: Scalars['String']['output'];
  function: Scalars['String']['output'];
  isInstalledPackage?: Maybe<Scalars['Boolean']['output']>;
  line: Scalars['String']['output'];
  locals?: Maybe<Scalars['JSON']['output']>;
  offender?: Maybe<Scalars['Boolean']['output']>;
};

export type TriageInputLogs = {
  logRecordIds?: InputMaybe<Array<Scalars['String']['input']>>;
  recordingId: Scalars['String']['input'];
};

export type TriageInputRecording = {
  identifiedProblemWindows?: InputMaybe<Array<RecordingTriageTimeWindow>>;
  recordingId: Scalars['String']['input'];
};

export type TriageLogDataType = {
  __typename?: 'TriageLogDataType';
  recording: RecordingSessionType;
  triage: TriageType;
};

export type TriageQueryResultsType = {
  __typename?: 'TriageQueryResultsType';
  queryType: Scalars['String']['output'];
  results: TriageTypePaginationWindow;
  tree: Scalars['String']['output'];
};

export type TriageRecordingDataType = {
  __typename?: 'TriageRecordingDataType';
  recording: RecordingSessionType;
  timeWindows: Array<TriageTimeWindow>;
  triage: TriageType;
};

export type TriageSimilarRecordingType = {
  __typename?: 'TriageSimilarRecordingType';
  recording: RecordingSessionType;
  triage: TriageType;
};

export type TriageTimeWindow = {
  __typename?: 'TriageTimeWindow';
  timestampEnd: Scalars['Float']['output'];
  timestampStart: Scalars['Float']['output'];
};

export type TriageType = {
  __typename?: 'TriageType';
  /** Affected % */
  affected?: Maybe<Scalars['String']['output']>;
  /** Changes made in the last 24h */
  change24h?: Maybe<Scalars['String']['output']>;
  company: DjangoModelType;
  createdAt: Scalars['DateTime']['output'];
  /** Updated time, human-readable timestamp */
  createdAtFormatted: Scalars['String']['output'];
  /** Identifier of who created the record */
  createdBy: Scalars['String']['output'];
  /** Devices */
  devices?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** Last seen */
  lastSeen?: Maybe<Scalars['String']['output']>;
  otherSimilarRecordings: Array<TriageSimilarRecordingType>;
  recordingSession?: Maybe<DjangoModelType>;
  /** Severity */
  severity?: Maybe<Scalars['String']['output']>;
  triageLogData: Array<TriageLogDataType>;
  triageRecordingData: Array<TriageRecordingDataType>;
  updatedAt: Scalars['DateTime']['output'];
  /** Created time, human-readable timestamp */
  updatedAtFormatted: Scalars['String']['output'];
  /** Identifier of who last updated the record */
  updatedBy: Scalars['String']['output'];
};

export type TriageTypePaginationWindow = {
  __typename?: 'TriageTypePaginationWindow';
  /** The list of items in this pagination window. */
  items: Array<TriageType>;
  /** Total number of items in the filtered dataset. */
  totalItemsCount: Scalars['Int']['output'];
};

export type TypeAheadBooleanField = {
  __typename?: 'TypeAheadBooleanField';
  name: Scalars['String']['output'];
  topNValues: Array<Scalars['Boolean']['output']>;
  totalValues: Scalars['Int']['output'];
};

export type TypeAheadJsonField = {
  __typename?: 'TypeAheadJsonField';
  fields: Array<TypeAheadTextField>;
  name: Scalars['String']['output'];
};

export type TypeAheadNumericField = {
  __typename?: 'TypeAheadNumericField';
  name: Scalars['String']['output'];
  topNValues: Array<Scalars['Float']['output']>;
  totalValues: Scalars['Int']['output'];
};

export type TypeAheadTextField = {
  __typename?: 'TypeAheadTextField';
  name: Scalars['String']['output'];
  topNValues: Array<Scalars['String']['output']>;
  totalValues: Scalars['Int']['output'];
};

export type TypeAheadTimeField = {
  __typename?: 'TypeAheadTimeField';
  end?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  start?: Maybe<Scalars['String']['output']>;
  totalValues: Scalars['Int']['output'];
};

export type TypingInteraction = {
  __typename?: 'TypingInteraction';
  attributes: Scalars['JSON']['output'];
  children?: Maybe<Array<Scalars['String']['output']>>;
  details: Scalars['String']['output'];
  element: Scalars['JSON']['output'];
  placeholder?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type UserInput = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type UserType = {
  __typename?: 'UserType';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  username: Scalars['String']['output'];
};

export type ValidationErrorDetailType = {
  __typename?: 'ValidationErrorDetailType';
  error: Scalars['String']['output'];
  suggestion: Scalars['String']['output'];
};

export type ValidationResult = {
  __typename?: 'ValidationResult';
  errorDetails: Array<ValidationErrorDetailType>;
  isJson: Scalars['Boolean']['output'];
  isScql: Scalars['Boolean']['output'];
  treeString: Scalars['String']['output'];
};

export type WebhookNotificationSettingsInput = {
  timeBetweenSuccessiveNotificationsPerIssue: Scalars['Duration']['input'];
};

export type WebhookNotificationSettingsType = {
  __typename?: 'WebhookNotificationSettingsType';
  timeBetweenSuccessiveNotificationsPerIssue: Scalars['Duration']['output'];
};

export type AuthenticateUserUsingAccessTokenMutationVariables = Exact<{
  accessToken: Scalars['String']['input'];
}>;


export type AuthenticateUserUsingAccessTokenMutation = { __typename?: 'Mutation', authenticateUserUsingAccessToken: { __typename?: 'AuthResponse', message: string, token: string, userId: string, email: string, picture: string, name: string } };

export type GetFullAutocompleteDataQueryVariables = Exact<{
  searchEntity: Scalars['String']['input'];
  itemIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  topN: Scalars['Int']['input'];
}>;


export type GetFullAutocompleteDataQuery = { __typename?: 'Query', getFullAutocompleteData: { __typename?: 'FullAutocompleteData', textFields: Array<{ __typename?: 'TypeAheadTextField', name: string, topNValues: Array<string>, totalValues: number }>, numericFields: Array<{ __typename?: 'TypeAheadNumericField', name: string, topNValues: Array<number>, totalValues: number }>, booleanFields: Array<{ __typename?: 'TypeAheadBooleanField', name: string, topNValues: Array<boolean>, totalValues: number }>, timeFields: Array<{ __typename?: 'TypeAheadTimeField', name: string, start?: string | null, end?: string | null, totalValues: number }>, jsonFields: Array<{ __typename?: 'TypeAheadJsonField', name: string, fields: Array<{ __typename?: 'TypeAheadTextField', name: string, topNValues: Array<string>, totalValues: number }> }>, allowedComparators: { __typename?: 'AllowedComparators', string: Array<string>, numeric: Array<string>, boolean: Array<string>, time: Array<string>, json: Array<string> } } };

export type GetFieldValuesQueryVariables = Exact<{
  fieldName: Scalars['String']['input'];
  searchEntity: Scalars['String']['input'];
  companyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetFieldValuesQuery = { __typename?: 'Query', getFieldValues: Array<string> };

export type SearchUsingQueryQueryVariables = Exact<{
  searchEntity: Scalars['String']['input'];
  searchQuery: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['Int']['input'];
}>;


export type SearchUsingQueryQuery = { __typename?: 'Query', searchUsingQuery?: { __typename?: 'BigQueryResultsType', queryType: string, tree: string, results: { __typename?: 'BigQueryVaryingStructurePaginationWindow', totalItemsCount: number, items: Array<{ __typename?: 'BigQueryVaryingStructure', data: any }> } } | { __typename?: 'IssueQueryResultsType', queryType: string, tree: string, results: { __typename?: 'IssueTypePaginationWindow', totalItemsCount: number, items: Array<{ __typename?: 'IssueType', id: string, createdAt: any, createdBy: string }> } } | { __typename?: 'RecordingSessionQueryResultsType', queryType: string, tree: string, results: { __typename?: 'RecordingSessionTypePaginationWindow', totalItemsCount: number, items: Array<{ __typename?: 'RecordingSessionType', id: string, formattedStartTimestamp: string, durationMs?: string | null, numberOfEventsSeen: number }> } } | { __typename?: 'TriageQueryResultsType', queryType: string, tree: string, results: { __typename?: 'TriageTypePaginationWindow', totalItemsCount: number, items: Array<{ __typename?: 'TriageType', id: string, createdAt: any, createdBy: string }> } } | null };

export type GetLatestBannerAnnouncementQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLatestBannerAnnouncementQuery = { __typename?: 'Query', latestBannerAnnouncement?: { __typename?: 'AnnouncementType', id: string, content: string, createdAt: string, type: string } | null };

export type GetSectionAnnouncementsQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
}>;


export type GetSectionAnnouncementsQuery = { __typename?: 'Query', allAnnouncements: { __typename?: 'PaginatedAnnouncements', page: number, pageSize: number, totalPages: number, totalItems: number, announcements: Array<{ __typename?: 'AnnouncementType', id: string, content: string, createdAt: string, type: string }> } };

export type DismissAnnouncementMutationVariables = Exact<{
  announcementId: Scalars['Int']['input'];
}>;


export type DismissAnnouncementMutation = { __typename?: 'Mutation', dismissAnnouncement: boolean };

export type CreateIssueMutationVariables = Exact<{
  input: CreateIssueInput;
  companyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type CreateIssueMutation = { __typename?: 'Mutation', createIssue?: { __typename?: 'IssueType', id: string, createdBy: string, createdAtFormatted: string, updatedAtFormatted: string } | null };

export type GetIssueQueryVariables = Exact<{
  issueId: Scalars['Int']['input'];
  companyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetIssueQuery = { __typename?: 'Query', getIssue?: { __typename?: 'IssueType', id: string, title?: string | null, description?: string | null, createdAt: any, createdBy: string, updatedBy: string, snoozedMode: string, snoozedUntilFormatted?: string | null, snoozedPermanently: boolean, isResolved: boolean, followers: Array<{ __typename?: 'UserType', email: string }>, exceptions: Array<{ __typename?: 'ExceptionRecordType', id: string, timestampMs: string, exceptionMessage?: string | null, file?: string | null, line?: number | null, function?: string | null, code?: string | null, traceStack: Array<{ __typename?: 'TraceFrameType', file: string, line: string, function: string, code: string, locals?: any | null, isInstalledPackage?: boolean | null }> }>, triages: Array<{ __typename?: 'TriageType', createdBy: string, updatedBy: string, triageRecordingData: Array<{ __typename?: 'TriageRecordingDataType', recording: { __typename?: 'RecordingSessionType', id: string, formattedStartTimestamp: string, durationMs?: string | null, numberOfEventsSeen: number }, timeWindows: Array<{ __typename?: 'TriageTimeWindow', timestampStart: number, timestampEnd: number }> }>, triageLogData: Array<{ __typename?: 'TriageLogDataType', recording: { __typename?: 'RecordingSessionType', id: string, formattedStartTimestamp: string, durationMs?: string | null, numberOfEventsSeen: number } }> }> } | null };

export type GetLogsForRecordingQueryVariables = Exact<{
  sessionId: Scalars['String']['input'];
  companyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetLogsForRecordingQuery = { __typename?: 'Query', getLogsForRecording: Array<{ __typename?: 'ConsoleLog', level: string, trace: Array<string>, payload: string, timestamp: string, formattedTimestamp: string }> };

export type GetRecordingSessionUnzippedEventsQueryVariables = Exact<{
  id: Scalars['String']['input'];
  companyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetRecordingSessionUnzippedEventsQuery = { __typename?: 'Query', recordingSession?: { __typename?: 'RecordingSessionEvents', unzippedEvents: Array<any>, pageVisits: Array<{ __typename?: 'PageVisitType', url: string, activeFrom: string, activeUntil?: string | null }>, userInteractions: { __typename?: 'InteractionsTaken', clicks: Array<{ __typename?: 'ClickInteraction', timestamp?: string | null, type: string, element: any, textContent: string, children?: Array<string> | null, details: string }>, pageNavigations: Array<{ __typename?: 'PageNavigationInteraction', timestamp?: string | null, url: string, details: string }>, textEdits: Array<{ __typename?: 'TypingInteraction', timestamp?: string | null, element: any, attributes: any, title?: string | null, placeholder?: string | null, children?: Array<string> | null, details: string }> }, metadata?: { __typename?: 'RecordingMetadata', user: string, language: string, timeZone?: string | null, region?: string | null, device: { __typename?: 'DeviceMetadata', isBot: boolean, isTelevision: boolean, isMobile: boolean, isDesktop: boolean, device?: { __typename?: 'DeviceDetails', brand?: string | null, model?: string | null, type?: string | null } | null, client?: { __typename?: 'ClientDetails', name?: string | null, type?: string | null, version?: string | null } | null, os?: { __typename?: 'OSDetails', name?: string | null, version?: string | null } | null } } | null } | null };

export type GetRecordingSessionsQueryVariables = Exact<{
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  companyId: Scalars['Int']['input'];
}>;


export type GetRecordingSessionsQuery = { __typename?: 'Query', recordingSessions?: { __typename?: 'RecordingSessionTypePaginationWindow', totalItemsCount: number, items: Array<{ __typename?: 'RecordingSessionType', id: string, formattedStartTimestamp: string, durationMs?: string | null, numberOfEventsSeen: number }> } | null };

export type GetJsSnippetConfigParametersQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetJsSnippetConfigParametersQuery = { __typename?: 'Query', jsSnippetConfigParameters?: { __typename?: 'JsSnippetConfigParametersType', backendApi: string, apiKey: string, scriptSource: string } | null };

export type GetSiteSettingsQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetSiteSettingsQuery = { __typename?: 'Query', siteSettings?: { __typename?: 'SiteSettingsType', apiKey: string, backendApi: string, sailfishBackendApi: string, defaultBackendApiUrl: string, defaultSailfishBackendApiUrl: string, isDefaultBackendApiUrl: boolean, isDefaultSailfishBackendApiUrl: boolean } | null };

export type UpdateSiteSettingsMutationVariables = Exact<{
  input: SiteSettingsInput;
  companyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type UpdateSiteSettingsMutation = { __typename?: 'Mutation', updateSiteSettings?: { __typename?: 'SiteSettingsType', backendApi: string, sailfishBackendApi: string, isDefaultBackendApiUrl: boolean, isDefaultSailfishBackendApiUrl: boolean } | null };

export type GetCaptureSettingsQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetCaptureSettingsQuery = { __typename?: 'Query', captureSettings?: { __typename?: 'CaptureSettingsType', recordCanvas: boolean, recordCrossOriginIframes: boolean, collectFonts: boolean, inlineImages: boolean, sampling: any, recordPassword: boolean, recordRealName: boolean, recordCreditCardInfo: boolean, recordSsn: boolean, recordDob: boolean, canEdit: boolean, ignoreDomains: Array<string> } | null };

export type UpdateCaptureSettingsMutationVariables = Exact<{
  input: CaptureSettingsInput;
}>;


export type UpdateCaptureSettingsMutation = { __typename?: 'Mutation', updateCaptureSettings?: { __typename?: 'CaptureSettingsType', recordCanvas: boolean, recordCrossOriginIframes: boolean, collectFonts: boolean, inlineImages: boolean, sampling: any, recordPassword: boolean, recordRealName: boolean, recordCreditCardInfo: boolean, recordSsn: boolean, recordDob: boolean, ignoreDomains: Array<string> } | null };

export type GetStatusItemsQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetStatusItemsQuery = { __typename?: 'Query', statusItems?: { __typename?: 'IntegrationStatusType', integrations: Array<{ __typename?: 'IntegrationType', title: string, link: string, tooltipText: string, isWorking: boolean }> } | null };

export type GetNotificationSettingsQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetNotificationSettingsQuery = { __typename?: 'Query', getNotificationSettings: { __typename?: 'NotificationsSettingsType', emailNotificationSettings: { __typename?: 'EmailNotificationSettingsType', defaultRecipients: any, timeBetweenSuccessiveNotificationsPerIssue: any }, webhookNotificationSettings: { __typename?: 'WebhookNotificationSettingsType', timeBetweenSuccessiveNotificationsPerIssue: any }, notificationWebhooks: Array<{ __typename?: 'NotificationWebhookType', id: string, webhookDataType: string, destinationAddress: string, authentication?: any | null }> } };

export type UpdateNotificationSettingsMutationVariables = Exact<{
  emailInput?: InputMaybe<EmailNotificationSettingsInput>;
  webhookInput?: InputMaybe<WebhookNotificationSettingsInput>;
  webhooks?: InputMaybe<Array<NotificationWebhookInput> | NotificationWebhookInput>;
  companyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type UpdateNotificationSettingsMutation = { __typename?: 'Mutation', updateNotificationSettings: boolean };

export type FollowIssueMutationVariables = Exact<{
  input: FollowIssueInput;
}>;


export type FollowIssueMutation = { __typename?: 'Mutation', followIssue: boolean };

export type UnfollowIssueMutationVariables = Exact<{
  input: FollowIssueInput;
}>;


export type UnfollowIssueMutation = { __typename?: 'Mutation', unfollowIssue: boolean };

export type ToggleIssueFollowingStatusForUserMutationVariables = Exact<{
  input: ToggleFollowIssueInput;
}>;


export type ToggleIssueFollowingStatusForUserMutation = { __typename?: 'Mutation', toggleIssueFollowingStatusForUser: boolean };

export type ToggleIssueResolvedStatusMutationVariables = Exact<{
  input: ResolveIssueInput;
}>;


export type ToggleIssueResolvedStatusMutation = { __typename?: 'Mutation', toggleIssueResolvedStatus: boolean };

export type SnoozeIssueMutationVariables = Exact<{
  input: SnoozeIssueInput;
}>;


export type SnoozeIssueMutation = { __typename?: 'Mutation', snoozeIssue: { __typename?: 'SnoozeIssueUpdateStatus', id: string, snoozedMode: string, snoozedUntil?: any | null, snoozedPermanently: boolean } };

export type CreateTriageMutationVariables = Exact<{
  triageInfoRecordings?: InputMaybe<Array<TriageInputRecording> | TriageInputRecording>;
  triageInfoLogs?: InputMaybe<Array<TriageInputLogs> | TriageInputLogs>;
  companyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type CreateTriageMutation = { __typename?: 'Mutation', createTriage?: { __typename?: 'TriageType', id: string, createdBy: string, updatedBy: string, createdAt: any, updatedAt: any } | null };

export type GetTriageQueryVariables = Exact<{
  triageId: Scalars['Int']['input'];
  companyId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetTriageQuery = { __typename?: 'Query', getTriage?: { __typename?: 'TriageType', affected?: string | null, change24h?: string | null, createdBy: string, createdAt: any, devices?: string | null, lastSeen?: string | null, severity?: string | null, updatedBy: string, triageRecordingData: Array<{ __typename?: 'TriageRecordingDataType', recording: { __typename?: 'RecordingSessionType', id: string, formattedStartTimestamp: string, durationMs?: string | null, numberOfEventsSeen: number }, timeWindows: Array<{ __typename?: 'TriageTimeWindow', timestampStart: number, timestampEnd: number }> }>, triageLogData: Array<{ __typename?: 'TriageLogDataType', recording: { __typename?: 'RecordingSessionType', id: string, formattedStartTimestamp: string, durationMs?: string | null, numberOfEventsSeen: number } }> } | null };

export type GetFeatureRequestsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFeatureRequestsQuery = { __typename?: 'Query', featureRequests: Array<{ __typename?: 'FeatureRequestType', id: string, title: string, description: string, totalVotes: number, companiesVoted: Array<string>, dateRequested: any, lastUpdate: any, votedByUser: boolean }> };

export type SubmitFeatureRequestMutationVariables = Exact<{
  title: Scalars['String']['input'];
  description: Scalars['String']['input'];
}>;


export type SubmitFeatureRequestMutation = { __typename?: 'Mutation', submitFeatureRequest?: { __typename?: 'FeatureRequestType', id: string, title: string, description: string, totalVotes: number, companiesVoted: Array<string>, dateRequested: any, lastUpdate: any } | null };

export type ToggleVoteMutationVariables = Exact<{
  requestId: Scalars['ID']['input'];
}>;


export type ToggleVoteMutation = { __typename?: 'Mutation', toggleVote: { __typename?: 'FeatureRequestType', id: string, totalVotes: number, votedByUser: boolean } };


export const AuthenticateUserUsingAccessTokenDocument = gql`
    mutation AuthenticateUserUsingAccessToken($accessToken: String!) {
  authenticateUserUsingAccessToken(accessToken: $accessToken) {
    message
    token
    userId
    email
    picture
    name
  }
}
    `;
export type AuthenticateUserUsingAccessTokenMutationFn = Apollo.MutationFunction<AuthenticateUserUsingAccessTokenMutation, AuthenticateUserUsingAccessTokenMutationVariables>;

/**
 * __useAuthenticateUserUsingAccessTokenMutation__
 *
 * To run a mutation, you first call `useAuthenticateUserUsingAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateUserUsingAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateUserUsingAccessTokenMutation, { data, loading, error }] = useAuthenticateUserUsingAccessTokenMutation({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useAuthenticateUserUsingAccessTokenMutation(baseOptions?: Apollo.MutationHookOptions<AuthenticateUserUsingAccessTokenMutation, AuthenticateUserUsingAccessTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthenticateUserUsingAccessTokenMutation, AuthenticateUserUsingAccessTokenMutationVariables>(AuthenticateUserUsingAccessTokenDocument, options);
      }
export type AuthenticateUserUsingAccessTokenMutationHookResult = ReturnType<typeof useAuthenticateUserUsingAccessTokenMutation>;
export type AuthenticateUserUsingAccessTokenMutationResult = Apollo.MutationResult<AuthenticateUserUsingAccessTokenMutation>;
export type AuthenticateUserUsingAccessTokenMutationOptions = Apollo.BaseMutationOptions<AuthenticateUserUsingAccessTokenMutation, AuthenticateUserUsingAccessTokenMutationVariables>;
export const GetFullAutocompleteDataDocument = gql`
    query getFullAutocompleteData($searchEntity: String!, $itemIds: [String!], $topN: Int!) {
  getFullAutocompleteData(
    searchEntity: $searchEntity
    itemIds: $itemIds
    topN: $topN
  ) {
    textFields {
      name
      topNValues
      totalValues
    }
    numericFields {
      name
      topNValues
      totalValues
    }
    booleanFields {
      name
      topNValues
      totalValues
    }
    timeFields {
      name
      start
      end
      totalValues
    }
    jsonFields {
      name
      fields {
        name
        topNValues
        totalValues
      }
    }
    allowedComparators {
      string
      numeric
      boolean
      time
      json
    }
  }
}
    `;

/**
 * __useGetFullAutocompleteDataQuery__
 *
 * To run a query within a React component, call `useGetFullAutocompleteDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFullAutocompleteDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFullAutocompleteDataQuery({
 *   variables: {
 *      searchEntity: // value for 'searchEntity'
 *      itemIds: // value for 'itemIds'
 *      topN: // value for 'topN'
 *   },
 * });
 */
export function useGetFullAutocompleteDataQuery(baseOptions: Apollo.QueryHookOptions<GetFullAutocompleteDataQuery, GetFullAutocompleteDataQueryVariables> & ({ variables: GetFullAutocompleteDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFullAutocompleteDataQuery, GetFullAutocompleteDataQueryVariables>(GetFullAutocompleteDataDocument, options);
      }
export function useGetFullAutocompleteDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFullAutocompleteDataQuery, GetFullAutocompleteDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFullAutocompleteDataQuery, GetFullAutocompleteDataQueryVariables>(GetFullAutocompleteDataDocument, options);
        }
export function useGetFullAutocompleteDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetFullAutocompleteDataQuery, GetFullAutocompleteDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetFullAutocompleteDataQuery, GetFullAutocompleteDataQueryVariables>(GetFullAutocompleteDataDocument, options);
        }
export type GetFullAutocompleteDataQueryHookResult = ReturnType<typeof useGetFullAutocompleteDataQuery>;
export type GetFullAutocompleteDataLazyQueryHookResult = ReturnType<typeof useGetFullAutocompleteDataLazyQuery>;
export type GetFullAutocompleteDataSuspenseQueryHookResult = ReturnType<typeof useGetFullAutocompleteDataSuspenseQuery>;
export type GetFullAutocompleteDataQueryResult = Apollo.QueryResult<GetFullAutocompleteDataQuery, GetFullAutocompleteDataQueryVariables>;
export const GetFieldValuesDocument = gql`
    query GetFieldValues($fieldName: String!, $searchEntity: String!, $companyId: Int) {
  getFieldValues(
    fieldName: $fieldName
    searchEntity: $searchEntity
    companyId: $companyId
  )
}
    `;

/**
 * __useGetFieldValuesQuery__
 *
 * To run a query within a React component, call `useGetFieldValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFieldValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFieldValuesQuery({
 *   variables: {
 *      fieldName: // value for 'fieldName'
 *      searchEntity: // value for 'searchEntity'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetFieldValuesQuery(baseOptions: Apollo.QueryHookOptions<GetFieldValuesQuery, GetFieldValuesQueryVariables> & ({ variables: GetFieldValuesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFieldValuesQuery, GetFieldValuesQueryVariables>(GetFieldValuesDocument, options);
      }
export function useGetFieldValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFieldValuesQuery, GetFieldValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFieldValuesQuery, GetFieldValuesQueryVariables>(GetFieldValuesDocument, options);
        }
export function useGetFieldValuesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetFieldValuesQuery, GetFieldValuesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetFieldValuesQuery, GetFieldValuesQueryVariables>(GetFieldValuesDocument, options);
        }
export type GetFieldValuesQueryHookResult = ReturnType<typeof useGetFieldValuesQuery>;
export type GetFieldValuesLazyQueryHookResult = ReturnType<typeof useGetFieldValuesLazyQuery>;
export type GetFieldValuesSuspenseQueryHookResult = ReturnType<typeof useGetFieldValuesSuspenseQuery>;
export type GetFieldValuesQueryResult = Apollo.QueryResult<GetFieldValuesQuery, GetFieldValuesQueryVariables>;
export const SearchUsingQueryDocument = gql`
    query searchUsingQuery($searchEntity: String!, $searchQuery: String!, $limit: Int!, $offset: Int!, $orderBy: String, $companyId: Int!) {
  searchUsingQuery(
    searchEntity: $searchEntity
    searchQuery: $searchQuery
    limit: $limit
    offset: $offset
    orderBy: $orderBy
    companyId: $companyId
  ) {
    ... on RecordingSessionQueryResultsType {
      results {
        items {
          id
          formattedStartTimestamp
          durationMs
          numberOfEventsSeen
        }
        totalItemsCount
      }
      queryType
      tree
    }
    ... on IssueQueryResultsType {
      results {
        items {
          id
          createdAt
          createdBy
        }
        totalItemsCount
      }
      queryType
      tree
    }
    ... on TriageQueryResultsType {
      results {
        items {
          id
          createdAt
          createdBy
        }
        totalItemsCount
      }
      queryType
      tree
    }
    ... on BigQueryResultsType {
      results {
        items {
          data
        }
        totalItemsCount
      }
      queryType
      tree
    }
  }
}
    `;

/**
 * __useSearchUsingQueryQuery__
 *
 * To run a query within a React component, call `useSearchUsingQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsingQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsingQueryQuery({
 *   variables: {
 *      searchEntity: // value for 'searchEntity'
 *      searchQuery: // value for 'searchQuery'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useSearchUsingQueryQuery(baseOptions: Apollo.QueryHookOptions<SearchUsingQueryQuery, SearchUsingQueryQueryVariables> & ({ variables: SearchUsingQueryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchUsingQueryQuery, SearchUsingQueryQueryVariables>(SearchUsingQueryDocument, options);
      }
export function useSearchUsingQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchUsingQueryQuery, SearchUsingQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchUsingQueryQuery, SearchUsingQueryQueryVariables>(SearchUsingQueryDocument, options);
        }
export function useSearchUsingQuerySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SearchUsingQueryQuery, SearchUsingQueryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchUsingQueryQuery, SearchUsingQueryQueryVariables>(SearchUsingQueryDocument, options);
        }
export type SearchUsingQueryQueryHookResult = ReturnType<typeof useSearchUsingQueryQuery>;
export type SearchUsingQueryLazyQueryHookResult = ReturnType<typeof useSearchUsingQueryLazyQuery>;
export type SearchUsingQuerySuspenseQueryHookResult = ReturnType<typeof useSearchUsingQuerySuspenseQuery>;
export type SearchUsingQueryQueryResult = Apollo.QueryResult<SearchUsingQueryQuery, SearchUsingQueryQueryVariables>;
export const GetLatestBannerAnnouncementDocument = gql`
    query GetLatestBannerAnnouncement {
  latestBannerAnnouncement {
    id
    content
    createdAt
    type
  }
}
    `;

/**
 * __useGetLatestBannerAnnouncementQuery__
 *
 * To run a query within a React component, call `useGetLatestBannerAnnouncementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestBannerAnnouncementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestBannerAnnouncementQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLatestBannerAnnouncementQuery(baseOptions?: Apollo.QueryHookOptions<GetLatestBannerAnnouncementQuery, GetLatestBannerAnnouncementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLatestBannerAnnouncementQuery, GetLatestBannerAnnouncementQueryVariables>(GetLatestBannerAnnouncementDocument, options);
      }
export function useGetLatestBannerAnnouncementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLatestBannerAnnouncementQuery, GetLatestBannerAnnouncementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLatestBannerAnnouncementQuery, GetLatestBannerAnnouncementQueryVariables>(GetLatestBannerAnnouncementDocument, options);
        }
export function useGetLatestBannerAnnouncementSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetLatestBannerAnnouncementQuery, GetLatestBannerAnnouncementQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLatestBannerAnnouncementQuery, GetLatestBannerAnnouncementQueryVariables>(GetLatestBannerAnnouncementDocument, options);
        }
export type GetLatestBannerAnnouncementQueryHookResult = ReturnType<typeof useGetLatestBannerAnnouncementQuery>;
export type GetLatestBannerAnnouncementLazyQueryHookResult = ReturnType<typeof useGetLatestBannerAnnouncementLazyQuery>;
export type GetLatestBannerAnnouncementSuspenseQueryHookResult = ReturnType<typeof useGetLatestBannerAnnouncementSuspenseQuery>;
export type GetLatestBannerAnnouncementQueryResult = Apollo.QueryResult<GetLatestBannerAnnouncementQuery, GetLatestBannerAnnouncementQueryVariables>;
export const GetSectionAnnouncementsDocument = gql`
    query GetSectionAnnouncements($page: Int!, $pageSize: Int!) {
  allAnnouncements(page: $page, pageSize: $pageSize) {
    announcements {
      id
      content
      createdAt
      type
    }
    page
    pageSize
    totalPages
    totalItems
  }
}
    `;

/**
 * __useGetSectionAnnouncementsQuery__
 *
 * To run a query within a React component, call `useGetSectionAnnouncementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSectionAnnouncementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSectionAnnouncementsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGetSectionAnnouncementsQuery(baseOptions: Apollo.QueryHookOptions<GetSectionAnnouncementsQuery, GetSectionAnnouncementsQueryVariables> & ({ variables: GetSectionAnnouncementsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSectionAnnouncementsQuery, GetSectionAnnouncementsQueryVariables>(GetSectionAnnouncementsDocument, options);
      }
export function useGetSectionAnnouncementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSectionAnnouncementsQuery, GetSectionAnnouncementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSectionAnnouncementsQuery, GetSectionAnnouncementsQueryVariables>(GetSectionAnnouncementsDocument, options);
        }
export function useGetSectionAnnouncementsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetSectionAnnouncementsQuery, GetSectionAnnouncementsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSectionAnnouncementsQuery, GetSectionAnnouncementsQueryVariables>(GetSectionAnnouncementsDocument, options);
        }
export type GetSectionAnnouncementsQueryHookResult = ReturnType<typeof useGetSectionAnnouncementsQuery>;
export type GetSectionAnnouncementsLazyQueryHookResult = ReturnType<typeof useGetSectionAnnouncementsLazyQuery>;
export type GetSectionAnnouncementsSuspenseQueryHookResult = ReturnType<typeof useGetSectionAnnouncementsSuspenseQuery>;
export type GetSectionAnnouncementsQueryResult = Apollo.QueryResult<GetSectionAnnouncementsQuery, GetSectionAnnouncementsQueryVariables>;
export const DismissAnnouncementDocument = gql`
    mutation DismissAnnouncement($announcementId: Int!) {
  dismissAnnouncement(announcementId: $announcementId)
}
    `;
export type DismissAnnouncementMutationFn = Apollo.MutationFunction<DismissAnnouncementMutation, DismissAnnouncementMutationVariables>;

/**
 * __useDismissAnnouncementMutation__
 *
 * To run a mutation, you first call `useDismissAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissAnnouncementMutation, { data, loading, error }] = useDismissAnnouncementMutation({
 *   variables: {
 *      announcementId: // value for 'announcementId'
 *   },
 * });
 */
export function useDismissAnnouncementMutation(baseOptions?: Apollo.MutationHookOptions<DismissAnnouncementMutation, DismissAnnouncementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DismissAnnouncementMutation, DismissAnnouncementMutationVariables>(DismissAnnouncementDocument, options);
      }
export type DismissAnnouncementMutationHookResult = ReturnType<typeof useDismissAnnouncementMutation>;
export type DismissAnnouncementMutationResult = Apollo.MutationResult<DismissAnnouncementMutation>;
export type DismissAnnouncementMutationOptions = Apollo.BaseMutationOptions<DismissAnnouncementMutation, DismissAnnouncementMutationVariables>;
export const CreateIssueDocument = gql`
    mutation CreateIssue($input: CreateIssueInput!, $companyId: Int) {
  createIssue(input: $input, companyId: $companyId) {
    id
    createdBy
    createdAtFormatted
    updatedAtFormatted
  }
}
    `;
export type CreateIssueMutationFn = Apollo.MutationFunction<CreateIssueMutation, CreateIssueMutationVariables>;

/**
 * __useCreateIssueMutation__
 *
 * To run a mutation, you first call `useCreateIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIssueMutation, { data, loading, error }] = useCreateIssueMutation({
 *   variables: {
 *      input: // value for 'input'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCreateIssueMutation(baseOptions?: Apollo.MutationHookOptions<CreateIssueMutation, CreateIssueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateIssueMutation, CreateIssueMutationVariables>(CreateIssueDocument, options);
      }
export type CreateIssueMutationHookResult = ReturnType<typeof useCreateIssueMutation>;
export type CreateIssueMutationResult = Apollo.MutationResult<CreateIssueMutation>;
export type CreateIssueMutationOptions = Apollo.BaseMutationOptions<CreateIssueMutation, CreateIssueMutationVariables>;
export const GetIssueDocument = gql`
    query GetIssue($issueId: Int!, $companyId: Int) {
  getIssue(issueId: $issueId, companyId: $companyId) {
    id
    title
    description
    createdAt
    createdBy
    updatedBy
    snoozedMode
    snoozedUntilFormatted
    snoozedPermanently
    isResolved
    followers {
      email
    }
    exceptions {
      id
      timestampMs
      exceptionMessage
      file
      line
      function
      code
      traceStack {
        file
        line
        function
        code
        locals
        isInstalledPackage
      }
    }
    triages {
      createdBy
      updatedBy
      triageRecordingData {
        recording {
          id
          formattedStartTimestamp
          durationMs
          numberOfEventsSeen
        }
        timeWindows {
          timestampStart
          timestampEnd
        }
      }
      triageLogData {
        recording {
          id
          formattedStartTimestamp
          durationMs
          numberOfEventsSeen
        }
      }
    }
  }
}
    `;

/**
 * __useGetIssueQuery__
 *
 * To run a query within a React component, call `useGetIssueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIssueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIssueQuery({
 *   variables: {
 *      issueId: // value for 'issueId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetIssueQuery(baseOptions: Apollo.QueryHookOptions<GetIssueQuery, GetIssueQueryVariables> & ({ variables: GetIssueQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIssueQuery, GetIssueQueryVariables>(GetIssueDocument, options);
      }
export function useGetIssueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIssueQuery, GetIssueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIssueQuery, GetIssueQueryVariables>(GetIssueDocument, options);
        }
export function useGetIssueSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetIssueQuery, GetIssueQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetIssueQuery, GetIssueQueryVariables>(GetIssueDocument, options);
        }
export type GetIssueQueryHookResult = ReturnType<typeof useGetIssueQuery>;
export type GetIssueLazyQueryHookResult = ReturnType<typeof useGetIssueLazyQuery>;
export type GetIssueSuspenseQueryHookResult = ReturnType<typeof useGetIssueSuspenseQuery>;
export type GetIssueQueryResult = Apollo.QueryResult<GetIssueQuery, GetIssueQueryVariables>;
export const GetLogsForRecordingDocument = gql`
    query GetLogsForRecording($sessionId: String!, $companyId: Int) {
  getLogsForRecording(sessionId: $sessionId, companyId: $companyId) {
    level
    trace
    payload
    timestamp
    formattedTimestamp
  }
}
    `;

/**
 * __useGetLogsForRecordingQuery__
 *
 * To run a query within a React component, call `useGetLogsForRecordingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLogsForRecordingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLogsForRecordingQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetLogsForRecordingQuery(baseOptions: Apollo.QueryHookOptions<GetLogsForRecordingQuery, GetLogsForRecordingQueryVariables> & ({ variables: GetLogsForRecordingQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLogsForRecordingQuery, GetLogsForRecordingQueryVariables>(GetLogsForRecordingDocument, options);
      }
export function useGetLogsForRecordingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLogsForRecordingQuery, GetLogsForRecordingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLogsForRecordingQuery, GetLogsForRecordingQueryVariables>(GetLogsForRecordingDocument, options);
        }
export function useGetLogsForRecordingSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetLogsForRecordingQuery, GetLogsForRecordingQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLogsForRecordingQuery, GetLogsForRecordingQueryVariables>(GetLogsForRecordingDocument, options);
        }
export type GetLogsForRecordingQueryHookResult = ReturnType<typeof useGetLogsForRecordingQuery>;
export type GetLogsForRecordingLazyQueryHookResult = ReturnType<typeof useGetLogsForRecordingLazyQuery>;
export type GetLogsForRecordingSuspenseQueryHookResult = ReturnType<typeof useGetLogsForRecordingSuspenseQuery>;
export type GetLogsForRecordingQueryResult = Apollo.QueryResult<GetLogsForRecordingQuery, GetLogsForRecordingQueryVariables>;
export const GetRecordingSessionUnzippedEventsDocument = gql`
    query GetRecordingSessionUnzippedEvents($id: String!, $companyId: Int) {
  recordingSession(id: $id, companyId: $companyId) {
    unzippedEvents
    pageVisits {
      url
      activeFrom
      activeUntil
    }
    userInteractions {
      clicks {
        timestamp
        type
        element
        textContent
        children
        details
      }
      pageNavigations {
        timestamp
        url
        details
      }
      textEdits {
        timestamp
        element
        attributes
        title
        placeholder
        children
        details
      }
    }
    metadata {
      user
      device {
        isBot
        isTelevision
        isMobile
        isDesktop
        device {
          brand
          model
          type
        }
        client {
          name
          type
          version
        }
        os {
          name
          version
        }
      }
      language
      timeZone
      region
    }
  }
}
    `;

/**
 * __useGetRecordingSessionUnzippedEventsQuery__
 *
 * To run a query within a React component, call `useGetRecordingSessionUnzippedEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecordingSessionUnzippedEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecordingSessionUnzippedEventsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetRecordingSessionUnzippedEventsQuery(baseOptions: Apollo.QueryHookOptions<GetRecordingSessionUnzippedEventsQuery, GetRecordingSessionUnzippedEventsQueryVariables> & ({ variables: GetRecordingSessionUnzippedEventsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecordingSessionUnzippedEventsQuery, GetRecordingSessionUnzippedEventsQueryVariables>(GetRecordingSessionUnzippedEventsDocument, options);
      }
export function useGetRecordingSessionUnzippedEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecordingSessionUnzippedEventsQuery, GetRecordingSessionUnzippedEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecordingSessionUnzippedEventsQuery, GetRecordingSessionUnzippedEventsQueryVariables>(GetRecordingSessionUnzippedEventsDocument, options);
        }
export function useGetRecordingSessionUnzippedEventsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetRecordingSessionUnzippedEventsQuery, GetRecordingSessionUnzippedEventsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetRecordingSessionUnzippedEventsQuery, GetRecordingSessionUnzippedEventsQueryVariables>(GetRecordingSessionUnzippedEventsDocument, options);
        }
export type GetRecordingSessionUnzippedEventsQueryHookResult = ReturnType<typeof useGetRecordingSessionUnzippedEventsQuery>;
export type GetRecordingSessionUnzippedEventsLazyQueryHookResult = ReturnType<typeof useGetRecordingSessionUnzippedEventsLazyQuery>;
export type GetRecordingSessionUnzippedEventsSuspenseQueryHookResult = ReturnType<typeof useGetRecordingSessionUnzippedEventsSuspenseQuery>;
export type GetRecordingSessionUnzippedEventsQueryResult = Apollo.QueryResult<GetRecordingSessionUnzippedEventsQuery, GetRecordingSessionUnzippedEventsQueryVariables>;
export const GetRecordingSessionsDocument = gql`
    query GetRecordingSessions($limit: Int!, $offset: Int!, $companyId: Int!) {
  recordingSessions(limit: $limit, offset: $offset, companyId: $companyId) {
    items {
      id
      formattedStartTimestamp
      durationMs
      numberOfEventsSeen
    }
    totalItemsCount
  }
}
    `;

/**
 * __useGetRecordingSessionsQuery__
 *
 * To run a query within a React component, call `useGetRecordingSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecordingSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecordingSessionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetRecordingSessionsQuery(baseOptions: Apollo.QueryHookOptions<GetRecordingSessionsQuery, GetRecordingSessionsQueryVariables> & ({ variables: GetRecordingSessionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecordingSessionsQuery, GetRecordingSessionsQueryVariables>(GetRecordingSessionsDocument, options);
      }
export function useGetRecordingSessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecordingSessionsQuery, GetRecordingSessionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecordingSessionsQuery, GetRecordingSessionsQueryVariables>(GetRecordingSessionsDocument, options);
        }
export function useGetRecordingSessionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetRecordingSessionsQuery, GetRecordingSessionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetRecordingSessionsQuery, GetRecordingSessionsQueryVariables>(GetRecordingSessionsDocument, options);
        }
export type GetRecordingSessionsQueryHookResult = ReturnType<typeof useGetRecordingSessionsQuery>;
export type GetRecordingSessionsLazyQueryHookResult = ReturnType<typeof useGetRecordingSessionsLazyQuery>;
export type GetRecordingSessionsSuspenseQueryHookResult = ReturnType<typeof useGetRecordingSessionsSuspenseQuery>;
export type GetRecordingSessionsQueryResult = Apollo.QueryResult<GetRecordingSessionsQuery, GetRecordingSessionsQueryVariables>;
export const GetJsSnippetConfigParametersDocument = gql`
    query GetJsSnippetConfigParameters($companyId: Int) {
  jsSnippetConfigParameters(companyId: $companyId) {
    backendApi
    apiKey
    scriptSource
  }
}
    `;

/**
 * __useGetJsSnippetConfigParametersQuery__
 *
 * To run a query within a React component, call `useGetJsSnippetConfigParametersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJsSnippetConfigParametersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJsSnippetConfigParametersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetJsSnippetConfigParametersQuery(baseOptions?: Apollo.QueryHookOptions<GetJsSnippetConfigParametersQuery, GetJsSnippetConfigParametersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJsSnippetConfigParametersQuery, GetJsSnippetConfigParametersQueryVariables>(GetJsSnippetConfigParametersDocument, options);
      }
export function useGetJsSnippetConfigParametersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJsSnippetConfigParametersQuery, GetJsSnippetConfigParametersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJsSnippetConfigParametersQuery, GetJsSnippetConfigParametersQueryVariables>(GetJsSnippetConfigParametersDocument, options);
        }
export function useGetJsSnippetConfigParametersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetJsSnippetConfigParametersQuery, GetJsSnippetConfigParametersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetJsSnippetConfigParametersQuery, GetJsSnippetConfigParametersQueryVariables>(GetJsSnippetConfigParametersDocument, options);
        }
export type GetJsSnippetConfigParametersQueryHookResult = ReturnType<typeof useGetJsSnippetConfigParametersQuery>;
export type GetJsSnippetConfigParametersLazyQueryHookResult = ReturnType<typeof useGetJsSnippetConfigParametersLazyQuery>;
export type GetJsSnippetConfigParametersSuspenseQueryHookResult = ReturnType<typeof useGetJsSnippetConfigParametersSuspenseQuery>;
export type GetJsSnippetConfigParametersQueryResult = Apollo.QueryResult<GetJsSnippetConfigParametersQuery, GetJsSnippetConfigParametersQueryVariables>;
export const GetSiteSettingsDocument = gql`
    query GetSiteSettings($companyId: Int) {
  siteSettings(companyId: $companyId) {
    apiKey
    backendApi
    sailfishBackendApi
    defaultBackendApiUrl
    defaultSailfishBackendApiUrl
    isDefaultBackendApiUrl
    isDefaultSailfishBackendApiUrl
  }
}
    `;

/**
 * __useGetSiteSettingsQuery__
 *
 * To run a query within a React component, call `useGetSiteSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteSettingsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetSiteSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetSiteSettingsQuery, GetSiteSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSiteSettingsQuery, GetSiteSettingsQueryVariables>(GetSiteSettingsDocument, options);
      }
export function useGetSiteSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSiteSettingsQuery, GetSiteSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSiteSettingsQuery, GetSiteSettingsQueryVariables>(GetSiteSettingsDocument, options);
        }
export function useGetSiteSettingsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetSiteSettingsQuery, GetSiteSettingsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSiteSettingsQuery, GetSiteSettingsQueryVariables>(GetSiteSettingsDocument, options);
        }
export type GetSiteSettingsQueryHookResult = ReturnType<typeof useGetSiteSettingsQuery>;
export type GetSiteSettingsLazyQueryHookResult = ReturnType<typeof useGetSiteSettingsLazyQuery>;
export type GetSiteSettingsSuspenseQueryHookResult = ReturnType<typeof useGetSiteSettingsSuspenseQuery>;
export type GetSiteSettingsQueryResult = Apollo.QueryResult<GetSiteSettingsQuery, GetSiteSettingsQueryVariables>;
export const UpdateSiteSettingsDocument = gql`
    mutation UpdateSiteSettings($input: SiteSettingsInput!, $companyId: Int) {
  updateSiteSettings(input: $input, companyId: $companyId) {
    backendApi
    sailfishBackendApi
    isDefaultBackendApiUrl
    isDefaultSailfishBackendApiUrl
  }
}
    `;
export type UpdateSiteSettingsMutationFn = Apollo.MutationFunction<UpdateSiteSettingsMutation, UpdateSiteSettingsMutationVariables>;

/**
 * __useUpdateSiteSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateSiteSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSiteSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSiteSettingsMutation, { data, loading, error }] = useUpdateSiteSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useUpdateSiteSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSiteSettingsMutation, UpdateSiteSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSiteSettingsMutation, UpdateSiteSettingsMutationVariables>(UpdateSiteSettingsDocument, options);
      }
export type UpdateSiteSettingsMutationHookResult = ReturnType<typeof useUpdateSiteSettingsMutation>;
export type UpdateSiteSettingsMutationResult = Apollo.MutationResult<UpdateSiteSettingsMutation>;
export type UpdateSiteSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateSiteSettingsMutation, UpdateSiteSettingsMutationVariables>;
export const GetCaptureSettingsDocument = gql`
    query GetCaptureSettings($companyId: Int) {
  captureSettings(companyId: $companyId) {
    recordCanvas
    recordCrossOriginIframes
    collectFonts
    inlineImages
    sampling
    recordPassword
    recordRealName
    recordCreditCardInfo
    recordSsn
    recordDob
    canEdit
    ignoreDomains
  }
}
    `;

/**
 * __useGetCaptureSettingsQuery__
 *
 * To run a query within a React component, call `useGetCaptureSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCaptureSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCaptureSettingsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCaptureSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetCaptureSettingsQuery, GetCaptureSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCaptureSettingsQuery, GetCaptureSettingsQueryVariables>(GetCaptureSettingsDocument, options);
      }
export function useGetCaptureSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCaptureSettingsQuery, GetCaptureSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCaptureSettingsQuery, GetCaptureSettingsQueryVariables>(GetCaptureSettingsDocument, options);
        }
export function useGetCaptureSettingsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCaptureSettingsQuery, GetCaptureSettingsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCaptureSettingsQuery, GetCaptureSettingsQueryVariables>(GetCaptureSettingsDocument, options);
        }
export type GetCaptureSettingsQueryHookResult = ReturnType<typeof useGetCaptureSettingsQuery>;
export type GetCaptureSettingsLazyQueryHookResult = ReturnType<typeof useGetCaptureSettingsLazyQuery>;
export type GetCaptureSettingsSuspenseQueryHookResult = ReturnType<typeof useGetCaptureSettingsSuspenseQuery>;
export type GetCaptureSettingsQueryResult = Apollo.QueryResult<GetCaptureSettingsQuery, GetCaptureSettingsQueryVariables>;
export const UpdateCaptureSettingsDocument = gql`
    mutation UpdateCaptureSettings($input: CaptureSettingsInput!) {
  updateCaptureSettings(input: $input) {
    recordCanvas
    recordCrossOriginIframes
    collectFonts
    inlineImages
    sampling
    recordPassword
    recordRealName
    recordCreditCardInfo
    recordSsn
    recordDob
    ignoreDomains
  }
}
    `;
export type UpdateCaptureSettingsMutationFn = Apollo.MutationFunction<UpdateCaptureSettingsMutation, UpdateCaptureSettingsMutationVariables>;

/**
 * __useUpdateCaptureSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateCaptureSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCaptureSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCaptureSettingsMutation, { data, loading, error }] = useUpdateCaptureSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCaptureSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCaptureSettingsMutation, UpdateCaptureSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCaptureSettingsMutation, UpdateCaptureSettingsMutationVariables>(UpdateCaptureSettingsDocument, options);
      }
export type UpdateCaptureSettingsMutationHookResult = ReturnType<typeof useUpdateCaptureSettingsMutation>;
export type UpdateCaptureSettingsMutationResult = Apollo.MutationResult<UpdateCaptureSettingsMutation>;
export type UpdateCaptureSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateCaptureSettingsMutation, UpdateCaptureSettingsMutationVariables>;
export const GetStatusItemsDocument = gql`
    query GetStatusItems($companyId: Int) {
  statusItems(companyId: $companyId) {
    integrations {
      title
      link
      tooltipText
      isWorking
    }
  }
}
    `;

/**
 * __useGetStatusItemsQuery__
 *
 * To run a query within a React component, call `useGetStatusItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatusItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatusItemsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetStatusItemsQuery(baseOptions?: Apollo.QueryHookOptions<GetStatusItemsQuery, GetStatusItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStatusItemsQuery, GetStatusItemsQueryVariables>(GetStatusItemsDocument, options);
      }
export function useGetStatusItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStatusItemsQuery, GetStatusItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStatusItemsQuery, GetStatusItemsQueryVariables>(GetStatusItemsDocument, options);
        }
export function useGetStatusItemsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetStatusItemsQuery, GetStatusItemsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetStatusItemsQuery, GetStatusItemsQueryVariables>(GetStatusItemsDocument, options);
        }
export type GetStatusItemsQueryHookResult = ReturnType<typeof useGetStatusItemsQuery>;
export type GetStatusItemsLazyQueryHookResult = ReturnType<typeof useGetStatusItemsLazyQuery>;
export type GetStatusItemsSuspenseQueryHookResult = ReturnType<typeof useGetStatusItemsSuspenseQuery>;
export type GetStatusItemsQueryResult = Apollo.QueryResult<GetStatusItemsQuery, GetStatusItemsQueryVariables>;
export const GetNotificationSettingsDocument = gql`
    query GetNotificationSettings($companyId: Int) {
  getNotificationSettings(companyId: $companyId) {
    emailNotificationSettings {
      defaultRecipients
      timeBetweenSuccessiveNotificationsPerIssue
    }
    webhookNotificationSettings {
      timeBetweenSuccessiveNotificationsPerIssue
    }
    notificationWebhooks {
      id
      webhookDataType
      destinationAddress
      authentication
    }
  }
}
    `;

/**
 * __useGetNotificationSettingsQuery__
 *
 * To run a query within a React component, call `useGetNotificationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationSettingsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetNotificationSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetNotificationSettingsQuery, GetNotificationSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationSettingsQuery, GetNotificationSettingsQueryVariables>(GetNotificationSettingsDocument, options);
      }
export function useGetNotificationSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationSettingsQuery, GetNotificationSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationSettingsQuery, GetNotificationSettingsQueryVariables>(GetNotificationSettingsDocument, options);
        }
export function useGetNotificationSettingsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetNotificationSettingsQuery, GetNotificationSettingsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetNotificationSettingsQuery, GetNotificationSettingsQueryVariables>(GetNotificationSettingsDocument, options);
        }
export type GetNotificationSettingsQueryHookResult = ReturnType<typeof useGetNotificationSettingsQuery>;
export type GetNotificationSettingsLazyQueryHookResult = ReturnType<typeof useGetNotificationSettingsLazyQuery>;
export type GetNotificationSettingsSuspenseQueryHookResult = ReturnType<typeof useGetNotificationSettingsSuspenseQuery>;
export type GetNotificationSettingsQueryResult = Apollo.QueryResult<GetNotificationSettingsQuery, GetNotificationSettingsQueryVariables>;
export const UpdateNotificationSettingsDocument = gql`
    mutation UpdateNotificationSettings($emailInput: EmailNotificationSettingsInput, $webhookInput: WebhookNotificationSettingsInput, $webhooks: [NotificationWebhookInput!], $companyId: Int) {
  updateNotificationSettings(
    emailSettings: $emailInput
    webhookSettings: $webhookInput
    webhooks: $webhooks
    companyId: $companyId
  )
}
    `;
export type UpdateNotificationSettingsMutationFn = Apollo.MutationFunction<UpdateNotificationSettingsMutation, UpdateNotificationSettingsMutationVariables>;

/**
 * __useUpdateNotificationSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationSettingsMutation, { data, loading, error }] = useUpdateNotificationSettingsMutation({
 *   variables: {
 *      emailInput: // value for 'emailInput'
 *      webhookInput: // value for 'webhookInput'
 *      webhooks: // value for 'webhooks'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useUpdateNotificationSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNotificationSettingsMutation, UpdateNotificationSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNotificationSettingsMutation, UpdateNotificationSettingsMutationVariables>(UpdateNotificationSettingsDocument, options);
      }
export type UpdateNotificationSettingsMutationHookResult = ReturnType<typeof useUpdateNotificationSettingsMutation>;
export type UpdateNotificationSettingsMutationResult = Apollo.MutationResult<UpdateNotificationSettingsMutation>;
export type UpdateNotificationSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateNotificationSettingsMutation, UpdateNotificationSettingsMutationVariables>;
export const FollowIssueDocument = gql`
    mutation FollowIssue($input: FollowIssueInput!) {
  followIssue(input: $input)
}
    `;
export type FollowIssueMutationFn = Apollo.MutationFunction<FollowIssueMutation, FollowIssueMutationVariables>;

/**
 * __useFollowIssueMutation__
 *
 * To run a mutation, you first call `useFollowIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followIssueMutation, { data, loading, error }] = useFollowIssueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFollowIssueMutation(baseOptions?: Apollo.MutationHookOptions<FollowIssueMutation, FollowIssueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FollowIssueMutation, FollowIssueMutationVariables>(FollowIssueDocument, options);
      }
export type FollowIssueMutationHookResult = ReturnType<typeof useFollowIssueMutation>;
export type FollowIssueMutationResult = Apollo.MutationResult<FollowIssueMutation>;
export type FollowIssueMutationOptions = Apollo.BaseMutationOptions<FollowIssueMutation, FollowIssueMutationVariables>;
export const UnfollowIssueDocument = gql`
    mutation UnfollowIssue($input: FollowIssueInput!) {
  unfollowIssue(input: $input)
}
    `;
export type UnfollowIssueMutationFn = Apollo.MutationFunction<UnfollowIssueMutation, UnfollowIssueMutationVariables>;

/**
 * __useUnfollowIssueMutation__
 *
 * To run a mutation, you first call `useUnfollowIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnfollowIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unfollowIssueMutation, { data, loading, error }] = useUnfollowIssueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnfollowIssueMutation(baseOptions?: Apollo.MutationHookOptions<UnfollowIssueMutation, UnfollowIssueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnfollowIssueMutation, UnfollowIssueMutationVariables>(UnfollowIssueDocument, options);
      }
export type UnfollowIssueMutationHookResult = ReturnType<typeof useUnfollowIssueMutation>;
export type UnfollowIssueMutationResult = Apollo.MutationResult<UnfollowIssueMutation>;
export type UnfollowIssueMutationOptions = Apollo.BaseMutationOptions<UnfollowIssueMutation, UnfollowIssueMutationVariables>;
export const ToggleIssueFollowingStatusForUserDocument = gql`
    mutation ToggleIssueFollowingStatusForUser($input: ToggleFollowIssueInput!) {
  toggleIssueFollowingStatusForUser(input: $input)
}
    `;
export type ToggleIssueFollowingStatusForUserMutationFn = Apollo.MutationFunction<ToggleIssueFollowingStatusForUserMutation, ToggleIssueFollowingStatusForUserMutationVariables>;

/**
 * __useToggleIssueFollowingStatusForUserMutation__
 *
 * To run a mutation, you first call `useToggleIssueFollowingStatusForUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleIssueFollowingStatusForUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleIssueFollowingStatusForUserMutation, { data, loading, error }] = useToggleIssueFollowingStatusForUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleIssueFollowingStatusForUserMutation(baseOptions?: Apollo.MutationHookOptions<ToggleIssueFollowingStatusForUserMutation, ToggleIssueFollowingStatusForUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleIssueFollowingStatusForUserMutation, ToggleIssueFollowingStatusForUserMutationVariables>(ToggleIssueFollowingStatusForUserDocument, options);
      }
export type ToggleIssueFollowingStatusForUserMutationHookResult = ReturnType<typeof useToggleIssueFollowingStatusForUserMutation>;
export type ToggleIssueFollowingStatusForUserMutationResult = Apollo.MutationResult<ToggleIssueFollowingStatusForUserMutation>;
export type ToggleIssueFollowingStatusForUserMutationOptions = Apollo.BaseMutationOptions<ToggleIssueFollowingStatusForUserMutation, ToggleIssueFollowingStatusForUserMutationVariables>;
export const ToggleIssueResolvedStatusDocument = gql`
    mutation ToggleIssueResolvedStatus($input: ResolveIssueInput!) {
  toggleIssueResolvedStatus(input: $input)
}
    `;
export type ToggleIssueResolvedStatusMutationFn = Apollo.MutationFunction<ToggleIssueResolvedStatusMutation, ToggleIssueResolvedStatusMutationVariables>;

/**
 * __useToggleIssueResolvedStatusMutation__
 *
 * To run a mutation, you first call `useToggleIssueResolvedStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleIssueResolvedStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleIssueResolvedStatusMutation, { data, loading, error }] = useToggleIssueResolvedStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleIssueResolvedStatusMutation(baseOptions?: Apollo.MutationHookOptions<ToggleIssueResolvedStatusMutation, ToggleIssueResolvedStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleIssueResolvedStatusMutation, ToggleIssueResolvedStatusMutationVariables>(ToggleIssueResolvedStatusDocument, options);
      }
export type ToggleIssueResolvedStatusMutationHookResult = ReturnType<typeof useToggleIssueResolvedStatusMutation>;
export type ToggleIssueResolvedStatusMutationResult = Apollo.MutationResult<ToggleIssueResolvedStatusMutation>;
export type ToggleIssueResolvedStatusMutationOptions = Apollo.BaseMutationOptions<ToggleIssueResolvedStatusMutation, ToggleIssueResolvedStatusMutationVariables>;
export const SnoozeIssueDocument = gql`
    mutation SnoozeIssue($input: SnoozeIssueInput!) {
  snoozeIssue(input: $input) {
    id
    snoozedMode
    snoozedUntil
    snoozedPermanently
  }
}
    `;
export type SnoozeIssueMutationFn = Apollo.MutationFunction<SnoozeIssueMutation, SnoozeIssueMutationVariables>;

/**
 * __useSnoozeIssueMutation__
 *
 * To run a mutation, you first call `useSnoozeIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSnoozeIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [snoozeIssueMutation, { data, loading, error }] = useSnoozeIssueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSnoozeIssueMutation(baseOptions?: Apollo.MutationHookOptions<SnoozeIssueMutation, SnoozeIssueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SnoozeIssueMutation, SnoozeIssueMutationVariables>(SnoozeIssueDocument, options);
      }
export type SnoozeIssueMutationHookResult = ReturnType<typeof useSnoozeIssueMutation>;
export type SnoozeIssueMutationResult = Apollo.MutationResult<SnoozeIssueMutation>;
export type SnoozeIssueMutationOptions = Apollo.BaseMutationOptions<SnoozeIssueMutation, SnoozeIssueMutationVariables>;
export const CreateTriageDocument = gql`
    mutation CreateTriage($triageInfoRecordings: [TriageInputRecording!], $triageInfoLogs: [TriageInputLogs!], $companyId: Int) {
  createTriage(
    triageInfoRecordings: $triageInfoRecordings
    triageInfoLogs: $triageInfoLogs
    companyId: $companyId
  ) {
    id
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
    `;
export type CreateTriageMutationFn = Apollo.MutationFunction<CreateTriageMutation, CreateTriageMutationVariables>;

/**
 * __useCreateTriageMutation__
 *
 * To run a mutation, you first call `useCreateTriageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTriageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTriageMutation, { data, loading, error }] = useCreateTriageMutation({
 *   variables: {
 *      triageInfoRecordings: // value for 'triageInfoRecordings'
 *      triageInfoLogs: // value for 'triageInfoLogs'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCreateTriageMutation(baseOptions?: Apollo.MutationHookOptions<CreateTriageMutation, CreateTriageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTriageMutation, CreateTriageMutationVariables>(CreateTriageDocument, options);
      }
export type CreateTriageMutationHookResult = ReturnType<typeof useCreateTriageMutation>;
export type CreateTriageMutationResult = Apollo.MutationResult<CreateTriageMutation>;
export type CreateTriageMutationOptions = Apollo.BaseMutationOptions<CreateTriageMutation, CreateTriageMutationVariables>;
export const GetTriageDocument = gql`
    query GetTriage($triageId: Int!, $companyId: Int) {
  getTriage(triageId: $triageId, companyId: $companyId) {
    affected
    change24h
    createdBy
    createdAt
    devices
    lastSeen
    severity
    triageRecordingData {
      recording {
        id
        formattedStartTimestamp
        durationMs
        numberOfEventsSeen
      }
      timeWindows {
        timestampStart
        timestampEnd
      }
    }
    triageLogData {
      recording {
        id
        formattedStartTimestamp
        durationMs
        numberOfEventsSeen
      }
    }
    updatedBy
  }
}
    `;

/**
 * __useGetTriageQuery__
 *
 * To run a query within a React component, call `useGetTriageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTriageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTriageQuery({
 *   variables: {
 *      triageId: // value for 'triageId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetTriageQuery(baseOptions: Apollo.QueryHookOptions<GetTriageQuery, GetTriageQueryVariables> & ({ variables: GetTriageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTriageQuery, GetTriageQueryVariables>(GetTriageDocument, options);
      }
export function useGetTriageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTriageQuery, GetTriageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTriageQuery, GetTriageQueryVariables>(GetTriageDocument, options);
        }
export function useGetTriageSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetTriageQuery, GetTriageQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTriageQuery, GetTriageQueryVariables>(GetTriageDocument, options);
        }
export type GetTriageQueryHookResult = ReturnType<typeof useGetTriageQuery>;
export type GetTriageLazyQueryHookResult = ReturnType<typeof useGetTriageLazyQuery>;
export type GetTriageSuspenseQueryHookResult = ReturnType<typeof useGetTriageSuspenseQuery>;
export type GetTriageQueryResult = Apollo.QueryResult<GetTriageQuery, GetTriageQueryVariables>;
export const GetFeatureRequestsDocument = gql`
    query GetFeatureRequests {
  featureRequests {
    id
    title
    description
    totalVotes
    companiesVoted
    dateRequested
    lastUpdate
    votedByUser
  }
}
    `;

/**
 * __useGetFeatureRequestsQuery__
 *
 * To run a query within a React component, call `useGetFeatureRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeatureRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeatureRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFeatureRequestsQuery(baseOptions?: Apollo.QueryHookOptions<GetFeatureRequestsQuery, GetFeatureRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeatureRequestsQuery, GetFeatureRequestsQueryVariables>(GetFeatureRequestsDocument, options);
      }
export function useGetFeatureRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeatureRequestsQuery, GetFeatureRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeatureRequestsQuery, GetFeatureRequestsQueryVariables>(GetFeatureRequestsDocument, options);
        }
export function useGetFeatureRequestsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetFeatureRequestsQuery, GetFeatureRequestsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetFeatureRequestsQuery, GetFeatureRequestsQueryVariables>(GetFeatureRequestsDocument, options);
        }
export type GetFeatureRequestsQueryHookResult = ReturnType<typeof useGetFeatureRequestsQuery>;
export type GetFeatureRequestsLazyQueryHookResult = ReturnType<typeof useGetFeatureRequestsLazyQuery>;
export type GetFeatureRequestsSuspenseQueryHookResult = ReturnType<typeof useGetFeatureRequestsSuspenseQuery>;
export type GetFeatureRequestsQueryResult = Apollo.QueryResult<GetFeatureRequestsQuery, GetFeatureRequestsQueryVariables>;
export const SubmitFeatureRequestDocument = gql`
    mutation SubmitFeatureRequest($title: String!, $description: String!) {
  submitFeatureRequest(title: $title, description: $description) {
    id
    title
    description
    totalVotes
    companiesVoted
    dateRequested
    lastUpdate
  }
}
    `;
export type SubmitFeatureRequestMutationFn = Apollo.MutationFunction<SubmitFeatureRequestMutation, SubmitFeatureRequestMutationVariables>;

/**
 * __useSubmitFeatureRequestMutation__
 *
 * To run a mutation, you first call `useSubmitFeatureRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitFeatureRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitFeatureRequestMutation, { data, loading, error }] = useSubmitFeatureRequestMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useSubmitFeatureRequestMutation(baseOptions?: Apollo.MutationHookOptions<SubmitFeatureRequestMutation, SubmitFeatureRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitFeatureRequestMutation, SubmitFeatureRequestMutationVariables>(SubmitFeatureRequestDocument, options);
      }
export type SubmitFeatureRequestMutationHookResult = ReturnType<typeof useSubmitFeatureRequestMutation>;
export type SubmitFeatureRequestMutationResult = Apollo.MutationResult<SubmitFeatureRequestMutation>;
export type SubmitFeatureRequestMutationOptions = Apollo.BaseMutationOptions<SubmitFeatureRequestMutation, SubmitFeatureRequestMutationVariables>;
export const ToggleVoteDocument = gql`
    mutation ToggleVote($requestId: ID!) {
  toggleVote(requestId: $requestId) {
    id
    totalVotes
    votedByUser
  }
}
    `;
export type ToggleVoteMutationFn = Apollo.MutationFunction<ToggleVoteMutation, ToggleVoteMutationVariables>;

/**
 * __useToggleVoteMutation__
 *
 * To run a mutation, you first call `useToggleVoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleVoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleVoteMutation, { data, loading, error }] = useToggleVoteMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useToggleVoteMutation(baseOptions?: Apollo.MutationHookOptions<ToggleVoteMutation, ToggleVoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleVoteMutation, ToggleVoteMutationVariables>(ToggleVoteDocument, options);
      }
export type ToggleVoteMutationHookResult = ReturnType<typeof useToggleVoteMutation>;
export type ToggleVoteMutationResult = Apollo.MutationResult<ToggleVoteMutation>;
export type ToggleVoteMutationOptions = Apollo.BaseMutationOptions<ToggleVoteMutation, ToggleVoteMutationVariables>;