import { Box, Button, Divider, Menu, MenuItem } from "@mui/material";
import React, {
  ReactNode,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import { CiCircleInfo, CiMenuKebab } from "react-icons/ci";
import { FaTrash } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import { IoReorderTwoOutline } from "react-icons/io5";

interface DashboardTileProps {
  id: string;
  onRemove: (id: string) => void;
  title?: string;
  children?: ReactNode;
}

const titleBackgroundColor = "#CAF0F8";

const DashboardTile = forwardRef<HTMLDivElement, DashboardTileProps>(
  ({ id, onRemove, title, children }, ref) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const handleClick = (event: React.MouseEvent<any>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleRemoveClick = () => {
      setShowConfirmation(true);
      handleClose();
    };

    const handleConfirmRemove = () => {
      onRemove(id);
      setShowConfirmation(false);
    };

    const handleCancelRemove = () => {
      setShowConfirmation(false);
    };

    useImperativeHandle(ref, () => ({} as HTMLDivElement));
    return (
      <Box
        id={id}
        ref={ref}
        className='shadow-md border border-gray-200 rounded-lg bg-white min-h-[200px] h-full relative overflow-visible flex flex-col'
      >
        {showConfirmation ? (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              bgcolor: "rgba(255, 255, 255, 0.9)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p>Are you sure you want to remove this tile?</p>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Button
                variant='outlined'
                onClick={handleConfirmRemove}
                color='error'
              >
                Confirm
              </Button>
              <Button variant='outlined' onClick={handleCancelRemove}>
                Cancel
              </Button>
            </Box>
          </Box>
        ) : (
          <>
            <div
              style={{
                backgroundColor: titleBackgroundColor,
                padding: "10px",
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <IoReorderTwoOutline
                className='drag-handle'
                style={{
                  cursor: "grab",
                  outline: "none",
                  border: "none",
                  height: "24px",
                  width: "24px",
                }}
              />
              <h3 style={{ margin: 0 }}>{title}</h3>
              <CiMenuKebab
                style={{ cursor: "pointer", height: "24px", width: "24px" }}
                onClick={handleClick}
              />
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={{
                  "& .MuiMenuItem-root:hover": {
                    bgcolor: "#dffaff",
                  },
                  "& .remove-menu-item:hover": {
                    bgcolor: "red",
                  },
                }}
              >
                <MenuItem
                  onClick={handleClose}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <CiCircleInfo
                    style={{
                      marginRight: "10px",
                      height: "24px",
                      width: "24px",
                    }}
                  />
                  Details
                </MenuItem>
                <MenuItem
                  onClick={handleClose}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <IoMdSettings
                    style={{
                      marginRight: "10px",
                      height: "24px",
                      width: "24px",
                    }}
                  />
                  Settings
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={handleRemoveClick}
                  className='remove-menu-item'
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    bgcolor: "lightcoral",
                  }}
                >
                  <FaTrash style={{ marginRight: "10px", width: "24px" }} />
                  Remove
                </MenuItem>
              </Menu>
            </div>
            <Box
              id='content-holder'
              style={{
                padding: "10px",
                overflow: "auto",
                flex: 1,
              }}
            >
              {children}
            </Box>
          </>
        )}
      </Box>
    );
  },
);

export default DashboardTile;
