import { useQuery } from "@apollo/client";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import React, { useCallback, useEffect, useRef, useState } from "react";
import client from "../../apolloClient";
import { useCompany } from "../../contexts/CompanyContext";
import { useUnsavedChanges } from "../../contexts/UnsavedChangesContext";
import { GET_JS_SNIPPET_CONFIG_PARAMETERS } from "../../graphql/settingsQueries";
import ActionBanner from "../ActionBanner";
import LoadingAnimation from "../LoadingAnimation";
import LoadingErrorModal from "../LoadingErrorModal";
import StyledText from "../StyledText";
import CodeBlock from "./CodeBlock";
import Section from "./Section";

const SetupInstall: React.FC = () => {
  const { selectedCompany } = useCompany();
  const { data, loading, error, refetch } = useQuery(
    GET_JS_SNIPPET_CONFIG_PARAMETERS,
    {
      client,
      variables: {
        companyId: selectedCompany
          ? parseInt(selectedCompany.value, 10)
          : undefined,
      },
    },
  );

  const {
    setExternalSaveFunction,
    markSettingAsChanged,
    clearUnsavedChanges,
    isDirty,
  } = useUnsavedChanges();

  const [showContent, setShowContent] = useState(false);
  const [htmlJsSnippet, setHtmlJsSnippet] = useState("");
  const [jsTsSnippet, setJsTsSnippet] = useState("");

  const jsTsInstallCodeNpmrc = `@sailfish-rrweb:registry=https://us-npm.pkg.dev/futureself-world/sailfishai-npm-public/
@sailfish:registry=https://us-npm.pkg.dev/futureself-world/sailfishai-npm-public/`;
  const jsTsInstallCodeNpmInstallPackageJs = `npm install --save @sailfish/recorder`;
  const jsTsInstallCodeNpmInstallPackageTsTypes = `npm install -D @sailfish-rrweb/types`;

  const pythonInstallCodePypirc = `[distutils]
index-servers =
    sailfishai-py

[sailfishai-py]
repository: https://us-python.pkg.dev/futureself-world/sailfishai-py/
`;
  const pythonInstallCodePipconf = `[global]
extra-index-url = https://us-python.pkg.dev/futureself-world/sailfishai-py/simple/
`;

  const pythonInstallCodePipInstallPackage = `pip install --upgrade sf-veritas==0.2.17`;
  const pythonSnippetWrapperBefore = `python manage.py runserver`;
  const pythonSnippetWrapperAfter = `sf-veritas python manage.py runserver`;
  const pythonSnippetRunCommandMainBefore = `if __name__ == "__main__":
    main()`;
  const pythonSnippetRunCommandMainAfter = `from sf_veritas.unified_interceptor import setup_interceptors

if __name__ == "__main__":
    setup_interceptors()  # Set up the interceptors immediately
    main()`;

  const pythonSnippetRunCommandExampleStandardBefore = `import os
import sys
import logging

logger = logging.getLogger(__name__)
...`;
  const pythonSnippetRunCommandExampleStandardAfter = `import os
import sys
import setup_interceptors

logger = logging.getLogger(__name__)
setup_interceptors()  # Set up the interceptors immediately
...`;

  const pyCode = `There is an early bug:
Make note that sometimes, if you have an issue in the application where the schema will not load, you will need to disable the Exception handler temporarily.
This shall be resolved shortly.
  `;

  const originalSettingsRef = useRef<{
    htmlJsSnippet: string;
    jsTsSnippet: string;
  } | null>(null);

  useEffect(() => {
    if (data && data.jsSnippetConfigParameters) {
      setHtmlJsSnippet(`<script>
  window.SailfishConfig = {
      BACKEND_API: '${data.jsSnippetConfigParameters.backendApi}',
      API_KEY: '${data.jsSnippetConfigParameters.apiKey}',
      domainsToPropagateHeaderTo: ["localhost:8000"] // Optional list of strings where the Sailfish header(s) will auto-propagate to (can include ports)
      domainsToNotPropagateHeaderTo: ["sentry.com", "localhost:9000"] // Optional list of strings where the Sailfish header(s) will not auto-propagate to (can include ports)
  };
</script>
<script src="${data.jsSnippetConfigParameters.scriptSource}"></script>`);

      setJsTsSnippet(`startRecording({
    BACKEND_API: '${data.jsSnippetConfigParameters.backendApi}',
    API_KEY: '${data.jsSnippetConfigParameters.apiKey}',
});`);

      originalSettingsRef.current = {
        htmlJsSnippet,
        jsTsSnippet,
      };

      setShowContent(true);
    }
  }, [data]);

  // Refetch the query whenever the selectedCompany changes
  useEffect(() => {
    if (selectedCompany) {
      refetch({
        companyId: parseInt(selectedCompany.value, 10),
      });
    } else {
      refetch({
        companyId: undefined,
      });
    }
  }, [selectedCompany, refetch]);

  const handleSaveChanges = useCallback(async () => {
    // Save the current settings
    if (originalSettingsRef.current) {
      originalSettingsRef.current = {
        htmlJsSnippet,
        jsTsSnippet,
      };
      clearUnsavedChanges("setup");
    }
  }, [htmlJsSnippet, jsTsSnippet, clearUnsavedChanges]);

  const handleDiscardChanges = useCallback(() => {
    if (originalSettingsRef.current) {
      setHtmlJsSnippet(originalSettingsRef.current.htmlJsSnippet);
      setJsTsSnippet(originalSettingsRef.current.jsTsSnippet);
      clearUnsavedChanges("setup");
    }
  }, [clearUnsavedChanges]);

  useEffect(() => {
    setExternalSaveFunction("setup", handleSaveChanges);
  }, [handleSaveChanges, setExternalSaveFunction]);

  // const handleSnippetChange = () => {
  //   markSettingAsChanged("setup");
  // };

  return (
    <>
      {loading && <LoadingAnimation text={"Loading..."} />}
      {error && <LoadingErrorModal error={error.message} />}
      {showContent && (
        <>
          <Section
            title='HTML JS Snippet (static/unmanaged sites)'
            defaultCollapsed={true}
          >
            <StyledText>
              If you are integrating with <strong>Shopify</strong> or a static
              site generator (e.g. <strong>Webflow</strong>), you can embed the
              following snippet:
            </StyledText>
            <CodeBlock title='HTML' code={htmlJsSnippet} />
          </Section>
          <Section title='JS/TS Library (frontend)'>
            <StyledText>
              When preparing to install using the JS/TS snippet, you will want
              to follow the instructions below. Our setup is simple - you are
              doing the following:
            </StyledText>
            <List component='ol' marker='decimal'>
              <ListItem>
                <StyledText>
                  Configure `npm` to install our package and its dependencies
                  from our NPM registry (hosted on Google Artifact Registry):
                </StyledText>
                <CodeBlock
                  title='Configure .npmrc'
                  code={jsTsInstallCodeNpmrc}
                />
              </ListItem>
              <ListItem nested>
                Install the required package(s) depending on if you are using
                JavaScript or TypeScript.
                <List marker='lower-alpha'>
                  <ListItem>
                    <StyledText>
                      Install the required package, `@sailfish/recorder` into
                      your repository:
                    </StyledText>
                    <CodeBlock
                      title='Install JS/TS Dependencies'
                      code={jsTsInstallCodeNpmInstallPackageJs}
                    />
                  </ListItem>
                  <ListItem>
                    <StyledText>
                      (TypeScript only) Install the TypeScript types
                      (`@sailfish-rrweb/types`) so your compiler does not
                      complain:
                    </StyledText>
                    <CodeBlock
                      title='Install TS Types'
                      code={jsTsInstallCodeNpmInstallPackageTsTypes}
                    />
                  </ListItem>
                </List>
              </ListItem>
              <ListItem>
                <StyledText>
                  Add the following snippet to the earliest part of your
                  application (typically `App.tsx` or `index.tsx`):
                </StyledText>
                <CodeBlock title='JS/TS' code={jsTsSnippet} />
              </ListItem>
            </List>
          </Section>
          <Section title='Python Library'>
            <StyledText>
              When preparing to install using the Python snippet, you will want
              to follow the instructions below. Our setup is simple - you are
              doing the following:
            </StyledText>
            <List component='ol' marker='decimal'>
              <ListItem>
                <StyledText>
                  Configure `pip` (via `.pypirc` or `pip.conf`) to install our
                  package and its dependencies from our PYPI registry (hosted on
                  Google Artifact Registry):
                </StyledText>
                <List marker='lower-alpha'>
                  <ListItem>
                    <CodeBlock
                      title='Add the following to .pypirc'
                      code={pythonInstallCodePypirc}
                    />
                  </ListItem>
                  <ListItem>
                    <CodeBlock
                      title='Add the following to pip.conf'
                      code={pythonInstallCodePipconf}
                    />
                  </ListItem>
                </List>
              </ListItem>
              <ListItem>
                <StyledText>
                  Install the required package, `sf-veritas` into your project:
                </StyledText>
                <CodeBlock
                  title='Install Python Dependencies'
                  code={pythonInstallCodePipInstallPackage}
                />
              </ListItem>
              <ListItem nested>
                You have 2 options for ensuring your data is properly captured:
                <List marker='lower-alpha'>
                  <ListItem>
                    <strong>Wrapper</strong>
                    <br />
                    <StyledText>
                      Add the following snippet wherever you run the command
                      `python ...`:
                    </StyledText>
                    <CodeBlock
                      title='Python (before)'
                      code={pythonSnippetWrapperBefore}
                    />
                    <CodeBlock
                      title='Python (after)'
                      code={pythonSnippetWrapperAfter}
                    />
                  </ListItem>
                  <ListItem>
                    <strong>Run Command</strong>
                    <br />
                    Add the following code before running anything, including
                    things like logging configuration, etc:
                    <br />
                    <p className='ml-4 text-slate-600'>
                      <i>
                        <StyledText>
                          **Note**: You can configure domains to exclude from
                          forwarding the `X-Sf3-Rid` header, which Sailfish uses
                          for tracing, using one of the following methods: 1.
                          **Environment Variable**: Set the
                          `DOMAINS_TO_NOT_PROPAGATE_HEADER_TO_ENVIRONMENT`
                          environment variable with a comma-separated list of
                          domains to exclude. Example: ``` export
                          DOMAINS_TO_NOT_PROPAGATE_HEADER_TO_ENVIRONMENT="example.com,www.sentry.com"
                          ``` 2. **Manual Configuration**: Pass the list of
                          domains directly to the `setup_interceptors()`
                          function via the `domains_to_not_propagate_headers_to`
                          parameter. Example: ```python setup_interceptors(
                          api_key=SAILFISH_API_KEY,
                          graphql_endpoint="https://api-service.sailfishqa.com/graphql/",
                          domains_to_not_propagate_headers_to=["example.com",
                          "www.sentry.com"] ) ``` **About `API_KEY`**: - The
                          `API_KEY` is used to authenticate with the Sailfish
                          service. - If not explicitly provided in
                          `setup_interceptors()`, it defaults to the
                          `SAILFISH_API_KEY` environment variable. - If the
                          environment variable is not set, an error is raised.
                          **About `GRAPHQL_ENDPOINT`**: - The `GRAPHQL_ENDPOINT`
                          is used to communicate with the Sailfish service. - If
                          not explicitly provided, it defaults to the
                          `SAILFISH_GRAPHQL_ENDPOINT` environment variable. - If
                          the environment variable is not set, it falls back to
                          the default value:
                          `https://api-service.sailfishqa.com/graphql/`. Choose
                          the method that best suits your use case to prevent
                          the propagation of the `X-Sf3-Rid` header to specified
                          domains.
                        </StyledText>
                      </i>
                    </p>
                    <List marker='lower-roman'>
                      <ListItem>
                        <StyledText>
                          Here's a standard script layout that is usually called
                          via `python main.py`
                        </StyledText>
                        <CodeBlock
                          title='Run Command (before)'
                          code={pythonSnippetRunCommandMainBefore}
                        />
                        <CodeBlock
                          title='Run Command (after)'
                          code={pythonSnippetRunCommandMainAfter}
                        />
                      </ListItem>
                      <ListItem>
                        <StyledText>
                          Here's an alternative configuration, whether you have
                          `if __name__ == "__main__"` or not:
                        </StyledText>
                        <CodeBlock
                          title='Run Command (before)'
                          code={pythonSnippetRunCommandExampleStandardBefore}
                        />
                        <CodeBlock
                          title='Run Command (after)'
                          code={pythonSnippetRunCommandExampleStandardAfter}
                        />
                      </ListItem>
                    </List>
                  </ListItem>
                </List>
              </ListItem>
            </List>
          </Section>
          {isDirty("setup") && (
            <ActionBanner
              onSave={handleSaveChanges}
              onDiscard={handleDiscardChanges}
              onUndo={() => {}}
              onRedo={() => {}}
              canUndo={false}
              canRedo={false}
            />
          )}
        </>
      )}
    </>
  );
};

export default SetupInstall;
