import React from "react";
import { NavLink } from "react-router-dom";
import { AiFillThunderbolt, AiOutlineQuestionCircle } from "react-icons/ai";
import { BiPlay, BiStop } from "react-icons/bi";
import { CgPlayBackwards, CgPlayForwards } from "react-icons/cg";
import { FaBriefcaseMedical, FaStethoscope } from "react-icons/fa";
import IconButton from "./IconButton";
import CtaButton from "./Buttons/CtaButton";

interface VideoPlayerControlsProps {
  videoDurationSeconds: number;
  currentTime: number;
  playbackSpeed: number;
  disabled: boolean;
  displayOnly: boolean;
  backwardRecordingDisabled: boolean;
  forwardRecordingDisabled: boolean;
  skipInactivity: boolean;
  isPlaying: boolean;
  isDefaultRegion?: boolean;
  hideTriageButton?: boolean;
  changeSpeed: (speed: number) => void;
  handleCreateTriage: () => void;
  handleBackwardRecording: () => void;
  handleForwardRecording: () => void;
  toggleDisplayMode: () => void;
  togglePlayPause: () => void;
  toggleSkipInactivity: () => void;
}

const VideoPlayerControls: React.FC<VideoPlayerControlsProps> = ({
  videoDurationSeconds,
  currentTime,
  playbackSpeed,
  disabled,
  displayOnly,
  backwardRecordingDisabled,
  forwardRecordingDisabled,
  skipInactivity,
  isPlaying,
  isDefaultRegion,
  hideTriageButton,
  changeSpeed,
  handleCreateTriage,
  handleBackwardRecording,
  handleForwardRecording,
  toggleDisplayMode,
  togglePlayPause,
  toggleSkipInactivity,
}) => {
  const formatTime = (timeInSeconds: number): string => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = Math.floor(timeInSeconds % 60);

    const formattedHours =
      videoDurationSeconds > 3600
        ? `${hours.toString().padStart(2, "0")}:`
        : "";
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");

    return `${formattedHours}${formattedMinutes}:${formattedSeconds}`;
  };

  const getCurrentTimeString = () => formatTime(currentTime / 1000);

  const getTotalTimeString = () => formatTime(videoDurationSeconds);

  const showConfirmation = !displayOnly && !isDefaultRegion;

  const withConfirmation = (cb: () => void) => () => {
    if (
      !showConfirmation ||
      confirm(
        "Are you sure you want to change the recording? You have unsaved changes.",
      )
    ) {
      cb();
    }
  };

  return (
    <div className='relative mt-4'>
      <div className='flex items-center w-full'>
        <div className='flex-1 flex gap-2'>
          <div
            id='time-display'
            className={`flex justify-start items-center text-lg mr-2 ${
              disabled ? "text-gray-400" : "text-black"
            } ${videoDurationSeconds > 3600 ? "w-40" : "w-28"}`}
          >
            <span>{getCurrentTimeString()}</span>
            <span className='mx-1'>/</span>
            <span>{getTotalTimeString()}</span>
          </div>
          <IconButton
            onClick={withConfirmation(handleBackwardRecording)}
            size='text-base'
            icon={CgPlayBackwards}
            disabled={disabled || backwardRecordingDisabled}
          />
          <IconButton
            onClick={togglePlayPause}
            size='text-base'
            flag={isPlaying}
            icon={BiPlay}
            trueIcon={BiStop}
            falseIcon={BiPlay}
            disabled={disabled}
          />
          <IconButton
            onClick={withConfirmation(handleForwardRecording)}
            size='text-base'
            icon={CgPlayForwards}
            disabled={disabled || forwardRecordingDisabled}
          />
        </div>
        {!hideTriageButton && (
        <div className='flex-1 flex justify-center gap-2 ml-2'>
          <CtaButton
            titlePrimary='Examine'
            titleSecondary='Quit'
            iconPrimary={<FaStethoscope />}
            bgPrimaryClass='bg-green-600'
            textPrimaryClass='text-white'
            bgPrimaryHoverClass='hover:bg-green-600'
            textPrimaryHoverClass='hover:text-white'
            bgSecondaryClass='bg-white'
            textSecondaryClass='text-red-500'
            bgSecondaryHoverClass='hover:bg-white'
            textSecondaryHoverClass='hover:text-red-500'
            onClick={toggleDisplayMode}
            isPrimary={displayOnly}
            showOutlineHoverPrimary={false}
            showOutlineHoverSecondary={false}
            additionalClassNames={`h-8 rounded-md text-sm leading-6 font-medium shadow-md ${
              displayOnly ? "" : " border border-red-500"
            }`}
            additionalWidth='24px'
            disabled={disabled}
            startIcon
          />
          <CtaButton
            titlePrimary='Triage'
            icon={<FaBriefcaseMedical />}
            bgPrimaryClass='bg-primary-blue-100'
            textPrimaryClass='text-white'
            bgPrimaryHoverClass='hover:bg-primary-blue-100'
            textPrimaryHoverClass='hover:text-white'
            onClick={handleCreateTriage}
            showOutlineHoverPrimary={false}
            additionalClassNames='h-8 rounded-md text-sm leading-6 font-medium shadow-md'
            additionalWidth='24px'
            disabled={displayOnly || isDefaultRegion}
            startIcon
          />
          <NavLink
            to='https://docs.sailfishqa.com/'
            className='link'
            target='_blank'
          >
            <IconButton
              size='text-lg'
              icon={AiOutlineQuestionCircle}
              disabled={disabled}
              tooltip='Click here to learn about Examine & Triage.'
              noShadow
            />
          </NavLink>
        </div>)}
        <div className='flex-1 flex justify-end gap-2'>
          <select
            value={playbackSpeed}
            onChange={(e) => changeSpeed(Number(e.target.value))}
            className={`btn btn-primary bg-inherit ${
              disabled ? "text-gray-400" : "text-black"
            }`}
            disabled={disabled}
          >
            <option value={1}>1x</option>
            <option value={2}>2x</option>
            <option value={4}>4x</option>
          </select>
          <IconButton
            onClick={toggleSkipInactivity}
            size='text-base'
            flag={skipInactivity}
            icon={AiFillThunderbolt}
            disabled={disabled}
            tooltip={`${skipInactivity ? "Show" : "Hide"} Inactivity`}
          />
        </div>
      </div>
    </div>
  );
};

export default VideoPlayerControls;
