import React from "react";

// Define the interface for the component props
export interface TimelineEvent {
  time: number;
  text: string;
  color?: string;
  Icon: React.ElementType;
  type: "pageNavigation" | "textEdit" | "click";
}

interface ActionPickerForAccordionProps {
  events: TimelineEvent[];
  onHoverEvent: (time: number) => void;
  onClickEvent: (time: number) => void;
}

const ActionPickerForAccordion: React.FC<ActionPickerForAccordionProps> = ({
  events,
  onHoverEvent,
  onClickEvent,
}) => {
  // Sort events by time, smallest to largest
  const sortedEvents = [...events].sort((a, b) => a.time - b.time);

  // Group events by page navigation
  const groupedEvents = sortedEvents.reduce((acc, event, index) => {
    if (event.type === "pageNavigation") {
      acc.push({ parent: event, children: [], index });
    } else if (acc.length > 0) {
      acc[acc.length - 1].children.push(event);
    }
    return acc;
  }, [] as { parent: TimelineEvent; children: TimelineEvent[]; index: number }[]);

  return (
    <div className='action-picker-accordion'>
      {groupedEvents.map(({ parent, children, index }) => (
        <div
          key={index}
          className='text-xs leading-5 font-medium text-blue-500'
        >
          <div className='flex items-center'>
            {/* Parent page navigation event */}
            <div
              className='action-item flex items-center cursor-pointer py-1 px-2 hover:bg-gray-200 max-w-full'
              onMouseEnter={() => onHoverEvent(parent.time)}
              onClick={(e) => {
                e.stopPropagation();
                onClickEvent(parent.time); // Seek to the time in the timeline
              }}
            >
              <div className='truncate'>{parent.text}</div>
            </div>
          </div>
          {/* Child events (clicks and text edits) */}
          <div className='ml-2 border-l-2 border-green-500 relative'>
            {children.map((child, childIndex) => (
              <div
                key={childIndex}
                className='action-item flex items-center cursor-pointer py-1 px-2 hover:bg-gray-200 max-w-full'
                onMouseEnter={() => onHoverEvent(child.time)}
                onClick={(e) => {
                  e.stopPropagation();
                  onClickEvent(child.time);
                }}
              >
                <div className='truncate'>{child.text}</div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ActionPickerForAccordion;
