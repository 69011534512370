import { gql } from "@apollo/client";

export const GET_JS_SNIPPET_CONFIG_PARAMETERS = gql`
  query GetJsSnippetConfigParameters($companyId: Int) {
    jsSnippetConfigParameters(companyId: $companyId) {
      backendApi
      apiKey
      scriptSource
    }
  }
`;

export const GET_SITE_SETTINGS = gql`
  query GetSiteSettings($companyId: Int) {
    siteSettings(companyId: $companyId) {
      apiKey
      backendApi
      sailfishBackendApi
      defaultBackendApiUrl
      defaultSailfishBackendApiUrl
      isDefaultBackendApiUrl
      isDefaultSailfishBackendApiUrl
    }
  }
`;

export const UPDATE_SITE_SETTINGS = gql`
  mutation UpdateSiteSettings($input: SiteSettingsInput!, $companyId: Int) {
    updateSiteSettings(input: $input, companyId: $companyId) {
      backendApi
      sailfishBackendApi
      isDefaultBackendApiUrl
      isDefaultSailfishBackendApiUrl
    }
  }
`;

export const GET_CAPTURE_SETTINGS = gql`
  query GetCaptureSettings($companyId: Int) {
    captureSettings(companyId: $companyId) {
      recordCanvas
      recordCrossOriginIframes
      collectFonts
      inlineImages
      sampling
      recordPassword
      recordRealName
      recordCreditCardInfo
      recordSsn
      recordDob
      canEdit
      ignoreDomains
    }
  }
`;

export const UPDATE_CAPTURE_SETTINGS = gql`
  mutation UpdateCaptureSettings($input: CaptureSettingsInput!) {
    updateCaptureSettings(input: $input) {
      recordCanvas
      recordCrossOriginIframes
      collectFonts
      inlineImages
      sampling
      recordPassword
      recordRealName
      recordCreditCardInfo
      recordSsn
      recordDob
      ignoreDomains
    }
  }
`;

export const GET_STATUS_ITEMS = gql`
  query GetStatusItems($companyId: Int) {
    statusItems(companyId: $companyId) {
      integrations {
        title
        link
        tooltipText
        isWorking
      }
    }
  }
`;

export const GET_NOTIFICATION_SETTINGS = gql`
  query GetNotificationSettings($companyId: Int) {
    getNotificationSettings(companyId: $companyId) {
      emailNotificationSettings {
        defaultRecipients
        timeBetweenSuccessiveNotificationsPerIssue
      }
      webhookNotificationSettings {
        timeBetweenSuccessiveNotificationsPerIssue
      }
      notificationWebhooks {
        id
        webhookDataType
        destinationAddress
        authentication
      }
    }
  }
`;

export const UPDATE_NOTIFICATION_SETTINGS = gql`
  mutation UpdateNotificationSettings(
    $emailInput: EmailNotificationSettingsInput
    $webhookInput: WebhookNotificationSettingsInput
    $webhooks: [NotificationWebhookInput!]
    $companyId: Int
  ) {
    updateNotificationSettings(
      emailSettings: $emailInput
      webhookSettings: $webhookInput
      webhooks: $webhooks
      companyId: $companyId
    )
  }
`;

export const FOLLOW_ISSUE = gql`
  mutation FollowIssue($input: FollowIssueInput!) {
    followIssue(input: $input)
  }
`;

export const UNFOLLOW_ISSUE = gql`
  mutation UnfollowIssue($input: FollowIssueInput!) {
    unfollowIssue(input: $input)
  }
`;

export const TOGGLE_ISSUE_FOLLOW_STATUS_FOR_USER = gql`
  mutation ToggleIssueFollowingStatusForUser($input: ToggleFollowIssueInput!) {
    toggleIssueFollowingStatusForUser(input: $input)
  }
`;

export const TOGGLE_ISSUE_RESOLVED_STATUS = gql`
  mutation ToggleIssueResolvedStatus($input: ResolveIssueInput!) {
    toggleIssueResolvedStatus(input: $input)
  }
`;

export const UPDATE_ISSUE_SNOOZE_STATUS = gql`
  mutation SnoozeIssue($input: SnoozeIssueInput!) {
    snoozeIssue(input: $input) {
      id
      snoozedMode
      snoozedUntil
      snoozedPermanently
    }
  }
`;
