import { List, ListItem, ListItemText, Tooltip } from "@mui/material";
import React from "react";
import {
  FaChevronDown,
  FaChevronLeft,
  FaChevronRight,
  FaMousePointer,
  FaPlayCircle,
  FaRegCalendarAlt,
  FaRegClock,
} from "react-icons/fa";
import GoToButton from "./GoToButton";
import LoadingAnimation from "./LoadingAnimation";
import ActionPickerForAccordion, {
  TimelineEvent,
} from "./Menus/ActionPickerForAccordion";

interface Recording {
  id: string;
  formattedStartTimestamp: string;
  durationMs: number;
  numberOfEventsSeen: number;
}

interface RecordingsListProps {
  recordings: Array<Recording>;
  onSelect: (recording: Recording) => void;
  page: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  loading: boolean;
  recordingId?: string;
  timelineEvents: TimelineEvent[];
  onClickEvent: (time: number) => void;
  activeTooltip?: string;
  inactiveTooltip?: string;
}

const RecordingsList: React.FC<RecordingsListProps> = ({
  recordings,
  onSelect,
  page,
  totalPages,
  onPageChange,
  loading,
  recordingId,
  timelineEvents,
  onClickEvent,
  activeTooltip,
  inactiveTooltip,
}) => {
  const formatDuration = (duration: number) => {
    const day = Math.floor(duration / 86400);
    const hour = Math.floor((duration % 86400) / 3600);
    const minute = Math.floor((duration % 3600) / 60);
    const second = duration % 60;

    if (day > 0) {
      return `${day}D ${Math.round(hour)}H`;
    }
    if (hour > 0) {
      return `${hour}H ${Math.round(minute)}M`;
    }
    return `${minute}M ${Math.round(second)}S`;
  };

  return (
    <div className='flex flex-col h-full'>
      <div className='flex-1 overflow-auto'>
        {loading ? (
          <LoadingAnimation text='Loading...' />
        ) : (
          <List>
            {recordings.map((recording) => (
              <ListItem
                button
                key={recording.id}
                onClick={() => onSelect(recording)}
                disableRipple
              >
                <ListItemText
                  primary={
                    <div className='flex gap-1 items-center'>
                      <div className='w-5'>
                        <FaPlayCircle
                          size={20}
                          className={
                            recordingId === recording.id
                              ? "text-green-500"
                              : "text-gray-400"
                          }
                        />
                      </div>
                      <Tooltip
                        title={
                          recordingId === recording.id
                            ? activeTooltip
                            : inactiveTooltip
                        }
                        arrow
                      >
                        <div className='truncate text-xs leading-5 font-medium text-blue-600'>
                          Recording {recording.id}
                        </div>
                      </Tooltip>
                    </div>
                  }
                  secondary={
                    <div className='flex flex-col'>
                      <div className='flex gap-4 ml-6 mt-1 items-center text-zinc-700 text-[10px] leading-4'>
                        <div className='flex items-center gap-1'>
                          {recordingId === recording.id ? (
                            <FaChevronDown size={12} />
                          ) : (
                            <FaChevronRight size={12} />
                          )}
                          <div className='text-blue-600 text-xs'>
                            {recording.numberOfEventsSeen}
                          </div>
                          <FaMousePointer
                            size={12}
                            className='text-slate-400'
                          />
                        </div>
                        <div className='flex items-center gap-0.5'>
                          <FaRegCalendarAlt
                            size={12}
                            className='text-slate-400'
                          />
                          <div>{recording.formattedStartTimestamp}</div>
                        </div>
                        <div className='flex items-center gap-0.5'>
                          <FaRegClock size={12} className='text-slate-400' />
                          <div>
                            {formatDuration(recording.durationMs / 1000)}
                          </div>
                        </div>
                      </div>
                      {recordingId === recording.id && (
                        <div className='ml-8'>
                          <ActionPickerForAccordion
                            events={timelineEvents}
                            onHoverEvent={() => {}}
                            onClickEvent={onClickEvent}
                          />
                        </div>
                      )}
                    </div>
                  }
                />
              </ListItem>
            ))}
          </List>
        )}
      </div>
      <div className='bg-white'>
        <div className='flex items-center justify-center mb-1'>
          <button
            className={`flex gap-1.5 items-center text-xs leading-[14px] font-medium text-neutral-700 py-2 px-1.5 ${
              page <= 1 ? "opacity-30" : ""
            }`}
            disabled={page <= 1}
            onClick={() => onPageChange(page - 1)}
          >
            <FaChevronLeft /> Previous
          </button>
          <div className='flex gap-2'>
            {page > 1 && (
              <button
                onClick={() => onPageChange(page - 1)}
                className='text-xs font-medium text-neutral-700 w-6 h-6 rounded hover:bg-zinc-100'
              >
                {page - 1}
              </button>
            )}
            <button className='text-xs font-medium text-neutral-700 w-6 h-6 border border-blue-500 rounded hover:bg-zinc-100'>
              {page}
            </button>
            {page < totalPages && (
              <button
                onClick={() => onPageChange(page + 1)}
                className='text-xs font-medium text-neutral-700 w-6 h-6 rounded hover:bg-zinc-100'
              >
                {page + 1}
              </button>
            )}
            <GoToButton totalPages={totalPages} onPageChange={onPageChange} />
          </div>
          <button
            className={`flex gap-1.5 items-center text-xs leading-[14px] font-medium text-neutral-700 py-2 px-1.5 ${
              page >= totalPages ? "opacity-30" : ""
            }`}
            disabled={page >= totalPages}
            onClick={() => onPageChange(page + 1)}
          >
            Next <FaChevronRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default RecordingsList;
