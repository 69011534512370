import { useState } from "react";
import { Snackbar } from "@mui/material";

interface CopyToClipboardButtonProps {
  title: string;
  startIcon?: React.ReactNode;
}

const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = ({
  title,
  startIcon,
}) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(window.location.toString());
  };

  return (
    <>
      <button
        onClick={handleClick}
        className='flex items-center bg-white text-black px-4 shadow rounded-md gap-1 text-sm font-medium'
      >
        {startIcon} {title}
      </button>
      <Snackbar
        message='Copied to clipboard'
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
        open={open}
      />
    </>
  );
};

export default CopyToClipboardButton;
