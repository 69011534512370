import { gql, useLazyQuery } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
// import { Helmet } from "react-helmet";
import { IconType } from "react-icons";
import { BsInputCursorText } from "react-icons/bs";
import { HiCursorClick } from "react-icons/hi";
import { IoMdBrowsers } from "react-icons/io";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ApplicationPage from "../components/ApplicationPage";
import RecordingsList from "../components/RecordingsListWithEvents";
import RRWebPlayerWithTriage from "../components/RRWebPlayerWithTriage";
// import SearchBar from "../components/Search/SearchBar"; // Assuming SearchBar component is in the same folder
import { useGetRecordingSessionsLazyQuery } from "../__generated__/graphql";
import client from "../apolloClient";
import SearchComboboxes from "../components/Search/SearchComboboxes";
import { useAuth } from "../contexts/AuthContext";
import { useCompany } from "../contexts/CompanyContext";
import { GET_RECORDING_SESSION_UNZIPPED_EVENTS } from "../graphql/recording";

const GET_RECORDING_SESSIONS = gql`
  query GetRecordingSessions($limit: Int!, $offset: Int!, $companyId: Int!) {
    recordingSessions(limit: $limit, offset: $offset, companyId: $companyId) {
      items {
        id
        formattedStartTimestamp
        durationMs
        numberOfEventsSeen
      }
      totalItemsCount
    }
  }
`;

interface Click {
  timestamp?: string;
  details: string;
  type: string;
  element: any;
  textContent: string;
  children?: any;
}

interface PageNavigation {
  timestamp?: string;
  details: string;
  url: string;
}

interface PageVisit {
  url: string;
  activeFrom: string;
  activeUntil?: string;
}

interface TextEdit {
  timestamp?: string;
  details: string;
  element: any;
  attributes: any;
  title?: string;
  placeholder?: string;
  children?: any;
}

interface Recording {
  id: string;
  formattedStartTimestamp: string;
  durationMs: number;
  numberOfEventsSeen: number;
}

interface TimelineEvent {
  time: number;
  text: string;
  color?: string;
  Icon: IconType;
  type: "pageNavigation" | "textEdit" | "click";
}

interface HighlightedSection {
  startTime: number;
  endTime: number;
  text: string;
  color: string;
}

type Metadata = {
  user: string;
  device: {
    os: {
      name: string;
      version: string;
    };
    client: {
      name: string;
      version: string;
    };
  };
  timeZone: string;
  language: string;
};

// const isDebugMode = false; // import.meta.env.REACT_APP_DEBUG_MODE === "true" || false;

// const fetchLocalEvents = async () => {
//   const response = await fetch(
//     "/rrweb-recordings/replay_2024_04_03T23_17_15.json",
//   );
//   const data = await response.json();
//   return data.data; // Ensure to return the correct structure
// };

const sanitizeEventAttributes = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(sanitizeEventAttributes);
  } else if (obj && typeof obj === "object") {
    const sanitizedObj = Object.keys(obj).reduce((acc, key) => {
      const sanitizedKey = key.replace("@", ""); // Remove '@' from key names
      acc[sanitizedKey] = sanitizeEventAttributes(obj[key]);
      return acc;
    }, {} as any);

    // Ensure required properties are present and valid
    if (sanitizedObj.type === 4 && !sanitizedObj.data) {
      sanitizedObj.data = {}; // Ensure data property is present for type 4 events
    }

    return sanitizedObj;
  }
  return obj;
};

const RECORDINGS_PER_PAGE = 10;
const PLAYER_HEIGHT_PX = 462;

const ReplaysWithSearchAndExaminePage = () => {
  const rrwebPlayerRef = useRef<any>(null);

  const { recordingId } = useParams<{ recordingId?: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const { selectedCompany } = useCompany();
  const [selectedRecording, setSelectedRecording] = useState<Recording | null>(
    null,
  );
  const [unzippedEvents, setUnzippedEvents] = useState<any>(null);
  const [timelineEvents, setTimelineEvents] = useState<TimelineEvent[]>([]);
  const [metadata, setMetadata] = useState<Metadata | null>(null);
  const pageInQuery = Number(searchParams.get("page"));
  const [page, setPage] = useState(pageInQuery || 1);
  const [recordings, setRecordings] = useState<Recording[]>([]);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [eventsError, setEventsError] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [containerWidth, setContainerWidth] = useState(0);
  // Select recording after fetching the list. -1 - select last item, 1 select first item, 0 don't select
  const [selectRecordingAfterFetch, setSelectRecordingAfterFetch] = useState(0);

  const handleSelectRecording = (recording: Recording) => {
    if (selectedRecording?.id === recording.id) {
      // Same recording selected, seek back to the start and keep playing
      rrwebPlayerRef.current?.resetPlayer();
    } else {
      // Different recording selected, pause the player and load new recording
      setUnzippedEvents(null); // Reset events to force RRWebPlayer re-initialization
      setTimelineEvents([]);
      setSelectedRecording(recording);
      navigate(
        `/recordings/${recording.id}${
          pageInQuery ? `?page=${pageInQuery}` : ""
        }`,
      );
    }
    rrwebPlayerRef.current?.pause();
  };

  const [
    fetchRecordings,
    { loading: loadingRecordings, error: errorRecordings },
  ] = useGetRecordingSessionsLazyQuery({
    client,
    variables: {
      limit: RECORDINGS_PER_PAGE,
      offset: (page - 1) * RECORDINGS_PER_PAGE,
      companyId: selectedCompany ? parseInt(selectedCompany.value, 10) : 0,
    },
    onCompleted: (data) => {
      if (!data.recordingSessions) return;
      const recordings = data.recordingSessions.items.map((item) => ({
        ...item,
        durationMs: item.durationMs ? parseInt(item.durationMs, 10) : 0, // Ensure it's a number
      }));
      setRecordings(recordings);
      setTotalItemsCount(data.recordingSessions.totalItemsCount);
      if (selectRecordingAfterFetch) {
        setSelectRecordingAfterFetch(0);
        handleSelectRecording(
          recordings[selectRecordingAfterFetch > 0 ? 0 : recordings.length - 1],
        );
      }
    },
  });

  const [fetchUnzippedEvents, { loading: loadingEvents, error }] = useLazyQuery(
    GET_RECORDING_SESSION_UNZIPPED_EVENTS,
    {
      onCompleted: (data) => {
        if (data && data.recordingSession) {
          setMetadata(data.recordingSession.metadata);
          const componentIconMap: { [key: string]: IconType } = {
            click: HiCursorClick,
            pageNavigation: IoMdBrowsers,
            textEdit: BsInputCursorText,
          };
          const componentColorMap: { [key: string]: string } = {
            click: "fuchsia-200",
            pageNavigation: "sky-200",
            textEdit: "rose-200",
          };

          const tempTimelineEvents: TimelineEvent[] = [];
          data.recordingSession.userInteractions.clicks.forEach(
            (click: Click) => {
              const type = "click";
              if (click.timestamp === undefined) {
                return;
              }
              tempTimelineEvents.push({
                time: +click.timestamp,
                text: `${click.type} on ${click.textContent}`,
                color: componentColorMap[type],
                Icon: componentIconMap[type],
                type,
              });
            },
          );
          if (data.recordingSession.pageVisits.length > 0) {
            data.recordingSession.pageVisits.forEach((pageVisit: PageVisit) => {
              const type = "pageNavigation";
              tempTimelineEvents.push({
                time: +pageVisit.activeFrom,
                text: `Navigated to ${pageVisit.url}`,
                color: componentColorMap[type],
                Icon: componentIconMap[type],
                type,
              });
            });
          } else {
            data.recordingSession.userInteractions.pageNavigations.forEach(
              (pageNavigation: PageNavigation) => {
                const type = "pageNavigation";
                if (pageNavigation.timestamp === undefined) {
                  return;
                }
                tempTimelineEvents.push({
                  time: +pageNavigation.timestamp,
                  text: `Navigated to ${pageNavigation.url}`,
                  color: componentColorMap[type],
                  Icon: componentIconMap[type],
                  type,
                });
              },
            );
          }
          data.recordingSession.userInteractions.textEdits.forEach(
            (textEdit: TextEdit) => {
              const type = "textEdit";
              if (textEdit.timestamp === undefined) {
                return;
              }
              const fieldTextIdentifier =
                textEdit.placeholder !== undefined
                  ? `Placeholder: ${textEdit.placeholder}`
                  : textEdit.title !== undefined
                  ? `Title: ${textEdit.title}`
                  : textEdit.details;

              tempTimelineEvents.push({
                time: +textEdit.timestamp,
                text: `Typed into field with ${fieldTextIdentifier}`,
                color: componentColorMap[type],
                Icon: componentIconMap[type],
                type,
              });
            },
          );
          setTimelineEvents(tempTimelineEvents);

          const sanitizedEvents = sanitizeEventAttributes(
            data.recordingSession.unzippedEvents,
          );

          if (sanitizedEvents.length === 0) {
            console.error(`Error: No events found for the given session.`);
            setEventsError(true);
            return;
          }

          if (sanitizedEvents.length < 2) {
            console.error(
              `Error: Insufficient events found for the given session.`,
            );
            setEventsError(true);
            return;
          }

          const firstEvent = sanitizedEvents.find(
            (event: any) => event.type === 4,
          );

          setUnzippedEvents(sanitizedEvents);
          setEventsError(false);
        }
      },
      onError: (error) => {
        setEventsError(true);
        console.error("Error fetching unzipped events:", error);
      },
    },
  );

  useEffect(() => {
    const fetchEvents = async () => {
      if (recordingId || selectedRecording) {
        const fetchVariables = selectedRecording
          ? {
              id: selectedRecording.id,
              companyId: selectedCompany
                ? parseInt(selectedCompany.value, 10)
                : undefined,
            }
          : {
              id: recordingId,
              companyId: selectedCompany
                ? parseInt(selectedCompany.value, 10)
                : undefined,
            };
        setEventsError(false);
        fetchUnzippedEvents({ variables: fetchVariables });
      }
    };

    fetchEvents();
  }, [
    recordingId,
    selectedRecording,
    selectedCompany,
    fetchUnzippedEvents,
    user?.email,
  ]);

  useEffect(() => {
    // Update container width on mount and resize
    const updateWidth = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };
    updateWidth();

    window.addEventListener("resize", updateWidth);
    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  useEffect(() => {
    fetchRecordings({
      variables: {
        limit: RECORDINGS_PER_PAGE,
        offset: (page - 1) * RECORDINGS_PER_PAGE,
        companyId: selectedCompany ? parseInt(selectedCompany.value, 10) : 0,
      },
    });
  }, [page, RECORDINGS_PER_PAGE, fetchRecordings, selectedCompany]);

  const handleSearchResults = (results: any[]) => {
    setRecordings(results);
  };

  const handleTotalItemsCount = (count: number) => {
    setTotalItemsCount(count); // Update the total items count
  };

  if (error) return <div>Error loading data</div>;

  const totalPages = Math.ceil(totalItemsCount / RECORDINGS_PER_PAGE);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
    setSearchParams((params) => {
      params.set("page", `${newPage}`);
      return params;
    });
  };

  useEffect(() => {
    // Calculate the new total pages based on the updated totalItemsCount
    const newTotalPages = Math.ceil(totalItemsCount / RECORDINGS_PER_PAGE);

    // If the current page exceeds the new total pages, set the page to the new max page
    if (page > newTotalPages && newTotalPages > 0) {
      setPage(newTotalPages);
      setSearchParams((params) => {
        params.set("page", `${newTotalPages}`);
        return params;
      });
    }
  }, [page, selectedCompany, totalItemsCount]);

  const currentRecordingIndex = recordings.findIndex(
    ({ id }) => id === recordingId || id === selectedRecording?.id,
  );
  const isFirstRecording = currentRecordingIndex === 0;
  const isLastRecording = currentRecordingIndex === recordings.length - 1;

  const handleBackwardRecording = () => {
    if (isFirstRecording) {
      setSelectRecordingAfterFetch(-1);
      handlePageChange(page - 1);
    } else {
      handleSelectRecording(recordings[currentRecordingIndex - 1]);
    }
  };

  const handleForwardRecording = () => {
    if (isLastRecording) {
      setSelectRecordingAfterFetch(1);
      handlePageChange(page + 1);
    } else {
      handleSelectRecording(recordings[currentRecordingIndex + 1]);
    }
  };

  const backwardRecordingDisabled = isFirstRecording && page === 1;
  const forwardRecordingDisabled = isLastRecording && page === totalPages;

  const highlightedSections: HighlightedSection[] = [];

  return (
    <ApplicationPage
      pageName='Recordings'
      helpLink='https://docs.sailfishqa.com/'
      helpTooltip='Click here to learn about Recordings.'
    >
      {/* <Helmet>
        <title>Sailfish - Recordings</title>
        <meta name='description' content='Nested component' />
      </Helmet> */}
      <div className='flex gap-4 max-h-[calc(100%-56px)]'>
        <div className='flex-[5] min-w-0'>
          <SearchComboboxes
            entity='Recordings'
            limit={RECORDINGS_PER_PAGE}
            offset={(page - 1) * RECORDINGS_PER_PAGE}
            onSearchResults={handleSearchResults}
            setTotalItemsCount={handleTotalItemsCount}
            onLoadingChange={setLoading}
          />
          <div ref={containerRef}>
            <div className='h-full'>
              <RRWebPlayerWithTriage
                key={recordingId || "none"} // Re-mount the component on video change so all calculations are re-done
                id={recordingId}
                ref={rrwebPlayerRef}
                events={unzippedEvents}
                timelineEvents={timelineEvents}
                highlightedSections={highlightedSections}
                width={containerWidth - 16}
                height={PLAYER_HEIGHT_PX}
                containerWidth={containerWidth}
                eventsError={eventsError}
                loadingEvents={loadingEvents}
                handleBackwardRecording={handleBackwardRecording}
                handleForwardRecording={handleForwardRecording}
                backwardRecordingDisabled={backwardRecordingDisabled}
                forwardRecordingDisabled={forwardRecordingDisabled}
              />
            </div>
          </div>
        </div>
        <div className='flex flex-[2] flex-col gap-2 max-h-full min-w-0'>
          <Accordion
            className='!shadow-md border border-gray-200 !rounded !m-0 flex flex-col p-2.5 overflow-auto'
            defaultExpanded
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1-content'
              id='panel1-header'
              className='text-sm font-semibold !min-h-8'
              classes={{ content: "!my-0" }}
            >
              All Recordings ({totalItemsCount || 0})
            </AccordionSummary>
            <RecordingsList
              recordings={recordings}
              onSelect={handleSelectRecording}
              page={page}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              loading={loadingRecordings}
              recordingId={recordingId}
              timelineEvents={timelineEvents}
              onClickEvent={(time) => {
                // Seek to the time in the player without changing its play state
                if (rrwebPlayerRef.current) {
                  rrwebPlayerRef.current.seekToTime(time);
                }
              }}
            />
          </Accordion>
          <Accordion
            className='!shadow-md border border-gray-200 !rounded !m-0 flex flex-col p-2.5 overflow-auto min-h-14'
            defaultExpanded
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel2-content'
              id='panel2-header'
              className='text-sm font-semibold !min-h-8'
              classes={{ content: "!my-0" }}
            >
              User Data
            </AccordionSummary>
            {metadata !== null ? (
              <div className='text-xs text-center flex flex-col gap-4'>
                <div className='flex flex-col gap-1'>
                  <div className='text-sm font-medium'>User</div>
                  <div>{metadata.user}</div>
                </div>
                <div className='flex flex-col gap-1'>
                  <div className='text-sm font-medium'>Device</div>
                  <div>
                    {metadata.device.os.name}, {metadata.device.os.version}
                    <br />
                    {`${metadata.device.client.name}, ${metadata.device.client.version}`}
                  </div>
                </div>
                <div className='flex flex-col gap-1'>
                  <div className='text-sm font-medium'>Time Zone</div>
                  <div>
                    {metadata.timeZone !== null ? metadata.timeZone : "UNKNOWN"}
                  </div>
                </div>
                <div className='flex flex-col gap-1'>
                  <div className='text-sm font-medium'>Language</div>
                  <div>{metadata.language}</div>
                </div>
              </div>
            ) : unzippedEvents ? (
              <div></div>
            ) : (
              <div className='text-center text-sm leading-6 text-zinc-700 flex flex-col min-h-16 justify-center px-8'>
                😬 nothing to show, play a video to get started
              </div>
            )}
          </Accordion>
        </div>
      </div>
    </ApplicationPage>
  );
};

export default ReplaysWithSearchAndExaminePage;
