import { gql, useLazyQuery } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import { useNavigate, useParams } from "react-router-dom";
import client from "../apolloClient";
import ApplicationPage from "../components/ApplicationPage";
import DashboardTile from "../components/DashboardTile";
import LoadingAnimation from "../components/LoadingAnimation";
import RRWebPlayer from "../components/RRWebPlayer";
import RecordingsList from "../components/RecordingsList";
import { useAuth } from "../contexts/AuthContext";
import { useCompany } from "../contexts/CompanyContext";
import { GET_RECORDING_SESSION_UNZIPPED_EVENTS } from "../graphql/recording";

const ResponsiveGridLayout = WidthProvider(Responsive);

const GET_RECORDING_SESSIONS = gql`
  query GetRecordingSessions($limit: Int!, $offset: Int!, $companyId: Int!) {
    recordingSessions(limit: $limit, offset: $offset, companyId: $companyId) {
      items {
        id
        formattedStartTimestamp
        durationMs
        numberOfEventsSeen
      }
      totalItemsCount
    }
  }
`;

interface Recording {
  id: string;
  formattedStartTimestamp: string;
  durationMs: number;
  numberOfEventsSeen: number;
}

interface TimelineEvent {
  time: number;
  text: string;
  color?: string;
  Icon: React.ComponentType;
}

interface HighlightedSection {
  startTime: number;
  endTime: number;
  text: string;
  color: string;
}

const DEFAULT_RECORDINGS_PER_PAGE = 25;

const isDebugMode = false; // import.meta.env.REACT_APP_DEBUG_MODE === "true" || false;

const fetchLocalEvents = async () => {
  const response = await fetch(
    "/rrweb-recordings/replay_2024_04_03T23_17_15.json",
  );
  const data = await response.json();
  return data.data; // Ensure to return the correct structure
};

const sanitizeEventAttributes = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(sanitizeEventAttributes);
  } else if (obj && typeof obj === "object") {
    const sanitizedObj = Object.keys(obj).reduce((acc, key) => {
      const sanitizedKey = key.replace("@", ""); // Remove '@' from key names
      acc[sanitizedKey] = sanitizeEventAttributes(obj[key]);
      return acc;
    }, {} as any);

    // Ensure required properties are present and valid
    if (sanitizedObj.type === 4 && !sanitizedObj.data) {
      sanitizedObj.data = {}; // Ensure data property is present for type 4 events
    }

    return sanitizedObj;
  }
  return obj;
};

const ReplaysPage = () => {
  const rrwebPlayerRef = useRef<any>(null); // <-- Call useRef here, outside of any condition

  const { recordingId } = useParams<{ recordingId?: string }>();
  const { selectedCompany } = useCompany();
  const [selectedRecording, setSelectedRecording] = useState<Recording | null>(
    null,
  );
  const [unzippedEvents, setUnzippedEvents] = useState<any>(null);
  const [page, setPage] = useState(1);
  const [recordings, setRecordings] = useState<Recording[]>([]);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [playerDimensions, setPlayerDimensions] = useState({
    width: 640,
    height: 480,
  });
  const [eventsError, setEventsError] = useState(false);
  const [recordingsPerPage, setRecordingsPerPage] = useState(
    DEFAULT_RECORDINGS_PER_PAGE,
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { user } = useAuth();

  const [fetchRecordings, { loading, error }] = useLazyQuery(
    GET_RECORDING_SESSIONS,
    {
      client,
      variables: {
        limit: recordingsPerPage,
        offset: (page - 1) * recordingsPerPage,
        companyId: selectedCompany ? parseInt(selectedCompany.value, 10) : 0,
      },
      onCompleted: (data) => {
        setRecordings(data.recordingSessions.items);
        setTotalItemsCount(data.recordingSessions.totalItemsCount);
      },
    },
  );

  const [fetchUnzippedEvents, { loading: loadingEvents }] = useLazyQuery(
    GET_RECORDING_SESSION_UNZIPPED_EVENTS,
    {
      onCompleted: (data) => {
        if (data && data.recordingSession) {
          const sanitizedEvents = sanitizeEventAttributes(
            data.recordingSession.unzippedEvents,
          );

          if (sanitizedEvents.length === 0) {
            console.error(`Error: No events found for the given session.`);
            setEventsError(true);
            return;
          }

          if (sanitizedEvents.length < 2) {
            console.error(
              `Error: Insufficient events found for the given session.`,
            );
            setEventsError(true);
            return;
          }

          const firstEvent = sanitizedEvents.find(
            (event: any) => event.type === 4,
          );

          if (firstEvent) {
            const { width, height } = firstEvent.data;
            setPlayerDimensions({ width, height });
          } else {
            setPlayerDimensions({ width: 640, height: 480 });
          }

          setUnzippedEvents(sanitizedEvents);
          setEventsError(false);
        }
      },
      onError: (error) => {
        setEventsError(true);
        console.error("Error fetching unzipped events:", error);
      },
    },
  );

  useEffect(() => {
    const fetchEvents = async () => {
      if (recordingId || selectedRecording) {
        if (isDebugMode) {
          const data = await fetchLocalEvents();
          if (data && data.recordingSession) {
            const sanitizedEvents = sanitizeEventAttributes(
              data.recordingSession.unzippedEvents,
            );
            const firstEvent = sanitizedEvents.find(
              (event: any) => event.type === 4,
            );
            if (firstEvent) {
              const { width, height } = firstEvent.data;
              setPlayerDimensions({ width, height });
            } else {
              setPlayerDimensions({ width: 640, height: 480 });
            }

            setUnzippedEvents(sanitizedEvents);
            setEventsError(false);
          }
        } else {
          const fetchVariables = selectedRecording
            ? {
                id: selectedRecording.id,
                companyId: selectedCompany
                  ? parseInt(selectedCompany.value, 10)
                  : undefined,
              }
            : {
                id: recordingId,
                companyId: selectedCompany
                  ? parseInt(selectedCompany.value, 10)
                  : undefined,
              };
          setEventsError(false);
          fetchUnzippedEvents({ variables: fetchVariables });
        }
      }
    };

    fetchEvents();
  }, [
    recordingId,
    selectedRecording,
    selectedCompany,
    fetchUnzippedEvents,
    user?.email,
  ]);

  useEffect(() => {
    fetchRecordings({
      variables: {
        limit: recordingsPerPage,
        offset: (page - 1) * recordingsPerPage,
        companyId: selectedCompany ? parseInt(selectedCompany.value, 10) : 0,
      },
    });
  }, [page, recordingsPerPage, fetchRecordings, selectedCompany]);

  useEffect(() => {
    if ((selectedRecording || recordingId) && unzippedEvents) {
      const handleResize = () => {
        if (containerRef.current) {
          const contentHolder = containerRef.current.querySelector(
            "#video-player #content-holder",
          ) as HTMLElement;
          if (contentHolder) {
            const padding = parseInt(
              window.getComputedStyle(contentHolder).padding,
              10,
            );
            const playbackBar = contentHolder.querySelector(
              "#rrweb-playback-bar",
            ) as HTMLElement;
            const playbackBarHeight = playbackBar
              ? playbackBar.offsetHeight +
                parseInt(window.getComputedStyle(playbackBar).marginTop, 10) +
                parseInt(window.getComputedStyle(playbackBar).marginBottom, 10)
              : 0;

            const contentWidth = contentHolder.offsetWidth - padding * 2;
            const contentHeight =
              contentHolder.offsetHeight - padding * 2 - playbackBarHeight;

            let width = contentWidth;
            let height = contentHeight;

            const aspectRatio =
              playerDimensions.width / playerDimensions.height;

            if (contentWidth / contentHeight > aspectRatio) {
              width = contentHeight * aspectRatio;
            } else {
              height = contentWidth / aspectRatio;
            }

            setPlayerDimensions({ width, height });
          } else {
            console.warn("content-holder not found");
          }
        }
      };

      handleResize();

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [
    selectedRecording,
    unzippedEvents,
    recordingId,
    playerDimensions.width,
    playerDimensions.height,
  ]);

  if (error) return <div>Error loading data</div>;

  const totalPages = Math.ceil(totalItemsCount / recordingsPerPage);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleSelectRecording = (recording: Recording) => {
    if (selectedRecording?.id === recording.id) {
      // Same recording selected, seek back to the start and keep playing
      rrwebPlayerRef.current?.resetPlayer();
    } else {
      // Different recording selected, pause the player and load new recording
      setUnzippedEvents(null); // Reset events to force RRWebPlayer re-initialization
      setSelectedRecording(recording);
      navigate(`/replays-users/${recording.id}`);
    }
    rrwebPlayerRef.current?.pause();
  };

  const timelineEvents: TimelineEvent[] = [];
  const highlightedSections: HighlightedSection[] = [];

  const tiles = [
    {
      id: "video-player",
      title: "Video Player",
      content: loadingEvents ? (
        <LoadingAnimation text={"Loading..."} />
      ) : unzippedEvents &&
        !eventsError &&
        (selectedRecording || recordingId) ? (
        <div className='h-full'>
          <RRWebPlayer
            ref={rrwebPlayerRef}
            events={unzippedEvents}
            timelineEvents={timelineEvents}
            highlightedSections={highlightedSections}
            width={playerDimensions.width}
            height={playerDimensions.height}
          />
        </div>
      ) : (
        <div className='h-full flex items-center justify-center'>
          {eventsError
            ? "Select a different recording; current recording is unfetchable"
            : "Select a Recording"}
        </div>
      ),
    },
    {
      id: "recordings-list",
      title: "Recordings",
      content: (
        <RecordingsList
          recordings={recordings}
          onSelect={handleSelectRecording}
          page={page}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          loading={loading}
          recordingsPerPage={recordingsPerPage}
          setRecordingsPerPage={setRecordingsPerPage}
        />
      ),
    },
  ];

  const layout = tiles.map((tile, index) => ({
    i: tile.id,
    x: (index % 2) * 2,
    y: Math.floor(index / 2),
    w: 4,
    h: 3,
  }));

  const handleTileResize = (layout: any, oldItem: any, newItem: any) => {
    if (containerRef.current) {
      const tileElement = containerRef.current.querySelector(
        `#${newItem.i}`,
      ) as HTMLElement;
      if (tileElement) {
        const contentHolder = tileElement.querySelector(
          "#content-holder",
        ) as HTMLElement;
        if (contentHolder) {
          const padding = parseInt(
            window.getComputedStyle(contentHolder).padding,
            10,
          );
          const playbackBar = tileElement.querySelector(
            "#rrweb-playback-bar",
          ) as HTMLElement;
          const playbackBarHeight = playbackBar
            ? playbackBar.offsetHeight +
              parseInt(window.getComputedStyle(playbackBar).marginTop, 10) +
              parseInt(window.getComputedStyle(playbackBar).marginBottom, 10)
            : 0;

          const contentWidth = contentHolder.offsetWidth - padding * 2;
          const contentHeight =
            contentHolder.offsetHeight - padding * 2 - playbackBarHeight;

          let width = contentWidth;
          let height = contentHeight;

          const aspectRatio = playerDimensions.width / playerDimensions.height;

          if (contentWidth / contentHeight > aspectRatio) {
            width = contentHeight * aspectRatio;
          } else {
            height = contentWidth / aspectRatio;
          }

          setPlayerDimensions({ width, height });
        } else {
          console.warn("content-holder not found");
        }
      } else {
        console.warn("tileElement not found");
      }
    }
  };

  return (
    <ApplicationPage pageName='Replays'>
      <div ref={containerRef}>
        <ResponsiveGridLayout
          className='layout'
          layouts={{ lg: layout }}
          breakpoints={{ lg: 1200 }}
          cols={{ lg: 4 }}
          rowHeight={100}
          width={1200}
          containerPadding={[15, 15]}
          draggableHandle='.drag-handle'
          onResizeStop={(layout, oldItem, newItem) =>
            handleTileResize(layout, oldItem, newItem)
          }
        >
          {tiles.map((tile) => (
            <div key={tile.id} className='p-0 m-0'>
              <DashboardTile
                key={tile.id}
                id={tile.id}
                title={tile.title}
                onRemove={() => {}}
              >
                {tile.content}
              </DashboardTile>
            </div>
          ))}
        </ResponsiveGridLayout>
      </div>
    </ApplicationPage>
  );
};

export default ReplaysPage;
