import React, { useState } from "react";

interface TagInputFieldProps {
  title?: string;
  tags: string[];
  setTags: (tags: string[]) => void;
}

const TagInputField: React.FC<TagInputFieldProps> = ({
  title,
  tags,
  setTags,
}) => {
  const [inputValue, setInputValue] = useState("");

  const handleAddTag = () => {
    const trimmedValue = inputValue.trim();
    if (trimmedValue && !tags.includes(trimmedValue)) {
      setTags([...tags, trimmedValue]);
    }
    setInputValue("");
  };

  const handleRemoveTag = (tagToRemove: string) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (["Enter", "Tab", ","].includes(e.key)) {
      e.preventDefault();
      handleAddTag();
    }
  };

  const helpMessage = "Type and press Enter, Tab, or comma";

  return (
    <div className='relative w-full'>
      {/* Show label inside the border */}
      <label className='absolute left-4 top-[-8px] bg-white px-1 text-gray-500 text-xs pointer-events-none cursor-default'>
        {title ? `${title} (${helpMessage})` : helpMessage}
      </label>

      {/* Input container */}
      <div className='flex items-center flex-wrap gap-2 border rounded-md p-2 pt-3 focus-within:ring-1 focus-within:ring-blue-500 focus-within:border-blue-500'>
        {/* Render tags */}
        {tags.map((tag) => (
          <div
            key={tag}
            className='flex items-center bg-blue-100 text-blue-700 px-2 py-1 rounded-md text-sm'
          >
            <span>{tag}</span>
            <button
              type='button'
              onClick={() => handleRemoveTag(tag)}
              className='ml-1 text-blue-500 hover:text-blue-700'
            >
              &times;
            </button>
          </div>
        ))}

        {/* Input field */}
        <input
          type='text'
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
          className='flex-1 min-w-20 border-none focus:ring-0 focus:outline-none text-sm h-7'
        />
      </div>
    </div>
  );
};

export default TagInputField;
