import React from "react";
import { Tooltip } from "@mui/material";

interface IconButtonProps {
  onClick?: () => void;
  size: string; // Tailwind CSS text size class, e.g., 'text-2xl'
  flag?: boolean; // True/false state flag
  icon: React.ElementType; // Default icon component
  trueIcon?: React.ElementType; // Icon component for true state (optional)
  falseIcon?: React.ElementType; // Icon component for false state (optional)
  disabled?: boolean; // True/false button disabled
  contained?: boolean; // True/false button is contained
  noShadow?: boolean; // True/false remove shadow
  className?: string; // Classname to adjust styles
  tooltip?: string; // Tooltip message
}

const IconButton: React.FC<IconButtonProps> = ({
  onClick,
  size,
  flag,
  icon: Icon,
  trueIcon: TrueIcon,
  falseIcon: FalseIcon,
  disabled,
  contained,
  noShadow,
  className = "",
  tooltip,
}) => {
  let textColor = "text-white";
  let background = "";
  if (contained) {
    background = disabled ? "bg-blue-300" : "bg-primary-blue-100";
  } else {
    textColor = disabled ? "text-blue-300" : "text-primary-blue-100";
    background = flag ? "border border-sky-300 bg-sky-50" : "bg-white";
  }

  return (
    <Tooltip title={tooltip} arrow>
      <button
        onClick={onClick}
        className={`flex justify-center items-center rounded-md ${
          noShadow ? "" : "shadow w-8"
        } h-8 ${size} ${textColor} focus:outline-none ${background} ${className}`}
        disabled={disabled}
      >
        {flag ? (
          TrueIcon ? (
            <TrueIcon />
          ) : (
            <Icon />
          )
        ) : FalseIcon ? (
          <FalseIcon />
        ) : (
          <Icon />
        )}
      </button>
    </Tooltip>
  );
};

export default IconButton;
