import { Box } from "@mui/material";
import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import IconButton from "./IconButton";

interface ApplicationPageProps {
  pageName: string;
  helpLink?: string;
  helpTooltip?: string;
  children: ReactNode;
}

const ApplicationPage: React.FC<ApplicationPageProps> = ({
  pageName,
  helpLink,
  helpTooltip,
  children,
}) => {
  return (
    <Box height={"100%"} width={"100%"}>
      <div className='flex justify-between'>
        <h1 id='applicationPageHeader' className='text-lg font-semibold pb-4'>
          {pageName}
        </h1>
        {helpLink && (
          <NavLink to={helpLink} className='link' target='_blank'>
            <IconButton
              size='text-lg'
              icon={AiOutlineQuestionCircle}
              tooltip={helpTooltip}
              noShadow
            />
          </NavLink>
        )}
      </div>
      {children}
    </Box>
  );
};

export default ApplicationPage;
